import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  TableCell,
  TableRow,
  IconButton,
  Box,
  Avatar,
  Tooltip,
} from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";

import { getData } from "/src/utils/getData";
import { Roles } from "/src/_helpers/role";
import { useAuthState } from "/src/contexts/UserAuthContext";
import {
  DELETE_TEMPLATE_ACTION_TYPE,
  useModalDispatch,
} from "/src/contexts/GlobalModalContext";

import Loading from "../Loading";
import NoDataFound from "../NoDataFound";
import YMTableDesktop from "../common/YMTableDesktop";

const tableCols = [
  {
    title: "Template",
    key: "templateImage",
  },
  {
    title: "Title",
    key: "title",
  },
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Description",
    key: "description",
  },
  {
    title: "Has Face",
    key: "hasFace",
  },
  {
    title: "Sort Order",
    key: "sortOrder",
  },
  {
    title: "Tags",
    key: "tags",
  },
  {
    title: "Action",
    key: "action",
  },
];

const TemplatesTable = () => {
  const modalDispatch = useModalDispatch();
  const navigate = useNavigate();
  const { user } = useAuthState();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (page === 1 && offset === 0) return;
    setOffset((page - 1) * limit);
  }, [page]);

  const fetchData = () => {
    return getData(`/meme-template?offset=${offset}&limit=${limit}`);
  };

  const { isLoading, data, error } = useQuery(
    ["templates", offset, limit],
    fetchData
  );

  useEffect(() => {
    if (data?.data && data?.data?.length > 0) {
      setTemplates(data.data);
    }
  }, [isLoading, data, error]);

  return (
    <YMTableDesktop
      cols={tableCols}
      count={data ? Number(data.count) : 0}
      page={page - 1}
      limit={limit}
      handlePageChange={(page) => setPage(Number(page))}
      handleLimitChange={(limit) => setLimit(Number(limit))}
    >
      {templates.length > 0 ? (
        templates.map((template, index) => (
          <TableRow key={index} hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Avatar
                  src={template.templateImage?.urlOptimised50x50}
                  sx={{ width: 56, height: 56 }}
                  variant='square'
                />
              </Box>
            </TableCell>
            <TableCell>{template.title}</TableCell>
            <TableCell>{template.id}</TableCell>
            <TableCell>{template.description}</TableCell>
            <TableCell>{template.hasFace ? "YES" : "NO"}</TableCell>
            <TableCell>{template.sortPosition}</TableCell>
            <TableCell>{template.tags.join(", ").trim()}</TableCell>
            <TableCell>
              <Box display='flex' width='100px' justifyContent='space-between'>
                <Tooltip title='Edit Template'>
                  <IconButton
                    variant='outlined'
                    color='info'
                    size='large'
                    onClick={() =>
                      navigate(`./edit/${template.id}`, {
                        state: template,
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                {user.role === Roles.SUPER_ADMIN ||
                user.role === Roles.ADMIN ? (
                  <Tooltip title='Delete Template'>
                    <IconButton
                      variant='contained'
                      color='error'
                      size='large'
                      onClick={() =>
                        modalDispatch({
                          type: DELETE_TEMPLATE_ACTION_TYPE,
                          value: {
                            ...template,
                            imageurl: template.templateImage?.urlOptimised50x50,
                          },
                        })
                      }
                    >
                      <DeleteForever />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Box>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={tableCols.length}>
            {isLoading ? <Loading /> : <NoDataFound error={error} />}
          </TableCell>
        </TableRow>
      )}
    </YMTableDesktop>
  );
};

TemplatesTable.propTypes = {
  data: PropTypes.array,
};

export default TemplatesTable;
