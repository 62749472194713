import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Select from './Select';
import Search from './Search';
// TODO: Styling does not match

export default function Filter({ title, handleChange, type='Select', items=[], disabled=false }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      border={( type==='Select') ? "1px solid rgba(224, 224, 224, 1)" : 0 }
      padding="0 10px"
      margin="0 10px 10px 0"
      borderRadius="5px"
    >
      <Typography
        color="textSecondary"
        variant="body2"
        sx={{ mr: 1 }}
        fontFamily="Poppins"
      >
        { (type === 'Select') ? title : '' }
      </Typography>
      <Box width="auto" minWidth="120px">
        {
          (type === 'Select')
          ? <Select items={items} onChange={handleChange} disabled={disabled} />
          : <Search title={title} onChange={handleChange} disabled={disabled} />
        }
      </Box>
    </Box>

  );
}

Filter.propTypes = {
  items: PropTypes.any,
  title: PropTypes.any
};
