import { useEffect, useState } from 'react'
import { Typography, Box, Autocomplete, TextField, Chip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import FileUpload from "./FileUpload";
import ValueInput from './ValueInput';
import DescField from './DescField';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import diff from '/src/utils/getObjectDiff';
import { useGlobalMutation } from '/src/utils/useGlobalMutations';
import { addNews, editNews, uploadNewsImage } from '/src/api/query';
import { useAlertDispatch } from '/src/contexts/GlobalAlertContext';
import { useErrorAction, useSuccessAction } from '/src/utils/mutationResponseAction';
import { useQueryClient } from 'react-query';

const TagInput = styled(TextField)(
    () => ({
        color: '#313131',
        backgroundColor: 'white',
        width: '100%',
        borderRadius: 5,
        marginBottom: 24,
        fontSize: 16,
        resize: 'none',
        outline: 'none',
        fontFamily: 'Poppins',
    })
);

const NewsForm = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const isCreateMode = !location.state;

    // Calls the action that will perform the add news action and update the news list
    const addNewsAction = useGlobalMutation(addNews);

    // Action for edit
    const editNewsAction = useGlobalMutation(editNews);

    // Action for image edit
    const uploadNewsImageAction = useGlobalMutation(uploadNewsImage);

    // Dispatch action for alerts
    const { successAlert, errorAlert } = useAlertDispatch();

    // actions the mutations will perform for each state
    const errorAction = useErrorAction();
    const successAction = useSuccessAction();

    const queryClient = useQueryClient();

    const SUPPORTED_FORMATS = [
        "image/png",
        "image/webp"
    ];

    const [newsData, setNewsData] = useState({
        title: null,
        description: null,
        tags: [],
        type: 'news',
    });

    const [newsImage, setNewsImage] = useState({
        previewImage: null,
        raw: null,
    });

    const [newsImageFormat, setNewsImageFormat] = useState(null);
    const [errors, setErrors] = useState({
        title: null,
        description: null,
        tags: null,
        imageFormat: null,
        imageSize: null,
    });

    const [ submitting, toggleSubmit ] = useState(false);

    // Effect for edit mode
    useEffect(() => {
        if (!isCreateMode) {
            setNewsImage(prev => ({
                ...prev, previewImage: location.state.image.urlOriginal
            }));
            setNewsData({
                title: location.state.name,
                description: location.state.description,
                tags: location.state.tags,
                type: 'news',
            })
        }
    }, []); 

    const validateImageFormat = (type) => {
        const isValid = SUPPORTED_FORMATS.includes(type);
        setErrors(prev => isValid ? ({ ...prev, imageFormat: null }) : ({ ...prev, imageFormat: " Image format is not supported"}));
        return isValid;
    }

    const validateImageSize = (size) => {
        const approvedImageSizeInBytes = 3000000;
        let isValid = false;

        if (size < approvedImageSizeInBytes) {
            setErrors(prev => ({ ...prev, imageSize: null }));
            isValid = true;
        }
        else {
            setErrors(prev => ({ ...prev, imageSize: "Image is too large!" }));
        }
        return isValid; 
    }

    const validateImage = (file) => {
        const isSizeValid = validateImageSize(file.size);
        const isFormatValid = validateImageFormat(file.type);

        return isSizeValid && isFormatValid;
    }

    const validate = (name, value) => {
        switch (name) {
            case 'title':
                const isValid = value.length > 0 && value.length < 160;
                setErrors(prev => isValid ? ({ ...prev, title: null}) : ({ ...prev, title: "Title should be between 1 and 160 characters."}));
                break;
            
            case 'description':
                const isCorrectLength = value.length > 0 && value.length < 1000;
                setErrors(prev => isCorrectLength ? ({ ...prev, description: null}) : ({ ...prev, description: 'Description should be between 1 and 1000 characters.'}));
                break;

            case 'tags':
                const minimumTag = value.length < 1;
                setErrors(prev => minimumTag ? ({ ...prev, tags: 'Minimum one tag should be added.'}) : ({ ...prev, tags: null}));
                const maxTag = value.length < 6;
                setErrors(prev => maxTag ? ({ ...prev }) : ({ ...prev, tags: 'Maximum 5 tags can be added.'}));
                break;

            default:
                break;
        }
    }

    const handleChange = (e) => {
        setNewsData({ ...newsData, [e.target.name]: e.target.value });
        validate(e.target.name, e.target.value);
    }

    const handleTagChange = (e) => {
        const newTags = [ ...newsData.tags, e.target.value ]
        setNewsData({ ...newsData, tags: newTags });
        validate('tags', newTags);
        // Validate tags
    }

    const handleTagRemove = (option) => {
        const newTags = [ ...newsData.tags.filter(tag => tag !== option)]
        setNewsData({ ...newsData, tags: newTags});
        validate('tags', newTags);
    }

    const handleFileUpload = (e) => {
        let file = e.target.files[0];
        let format = file.type.replace('image/', '');
        let fileValid = validateImage(file);
        setNewsImage({
            previewImage: URL.createObjectURL(file),
            raw: file
        });
        setNewsImageFormat(format);

        // sends image to server if in edit mode and valid
        if (!isCreateMode && fileValid) {
            toggleSubmit(true);
            uploadNewsImageAction.mutate({
                id: location.state.id,
                image: {
                    type: format,
                    size: file.size
                },
                data: file
            }, {
                onSuccess: () => {
                    toggleSubmit(false);
                    successAlert(`News image updated!`);
                },
                onError: (error) => {
                    toggleSubmit(false);
                    errorAlert(error.message);
                }
            })
        }
    }

    const preSubmit = (values) => {
        let initialValues = {
            title: location.state.title,
            describe: location.state.description,
            tags: location.state.tags
        }

        let finalValues = values;
        finalValues = diff(initialValues, values);
        return finalValues;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', newsData.title);
        formData.append('description', newsData.description);
        formData.append('tags', JSON.stringify(newsData.tags));
        formData.append('type', newsData.type);

        if (isCreateMode) {
            formData.append('sourceImageFormat', newsImageFormat);
            formData.append('rendered', newsImage.raw);

            let fileValid = validateImage(newsImage.raw);
            if (fileValid && errors.title == null && errors.description == null && errors.tags == null) {
                toggleSubmit(true);
                addNewsAction.mutate(formData, {
                    onSuccess: (data, variables, contexts) => {
                        successAction(['posts', 'news'], "/dashboard/news", "News Added");
                        toggleSubmit(false);
                    },
                    onError: (error, variables, contexts) => {
                        errorAction(error.message);
                        toggleSubmit(false);
                    },
                })
            }
        } else {
            const editedData = preSubmit({
                title: newsData.title,
                describe: newsData.description,
                tags: newsData.description
            });
            toggleSubmit(true);
            editNewsAction.mutate({
                id: location.state.id,
                data: editedData
            }, {
                onSuccess: (data) => {
                    successAlert(`News updated`);
                    queryClient.setQueryData(['posts', 'news', {id: location.state.id }], data);
                    toggleSubmit(false);
                    navigate("/dashboard/news")
                },
                onError: (error) => {
                    toggleSubmit(false);
                    errorAlert(error.message);
                }
            })
        }
    }

    return (
        <Box sx={{
            maxWidth: 'md'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3
                }}
            >
                <FileUpload
                    required
                    name="newsImage"
                    value={newsImage}
                    accept={SUPPORTED_FORMATS.join(', ')}
                    onImageChange={handleFileUpload}
                />
                <Box
                    sx={{
                        ml: 5
                    }}
                >
                    <Typography
                        color="#313131"
                        fontSize="16px"
                    >
                        Upload Image
                    </Typography>
                    <Typography
                        color="#828282"
                        fontSize="14px"
                    >
                        80px &times; 80px at Max 3MB
                    </Typography>

                    <Typography color="#ff0000">
                        {errors.imageSize && errors.imageSize}
                    </Typography>
                    <Typography color="#ff0000">
                        {errors.imageFormat && errors.imageFormat}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                maxWidth: 200,
                my: 3,
                '& img': {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                }
            }}>
                {newsImage.previewImage ? <img src={newsImage.previewImage} alt="news preview" /> : null}
            </Box>
            <form autoComplete='off' noValidate onSubmit={handleSubmit}>
                <Box>
                    <ValueInput
                        variant='outlined'
                        required
                        placeholder='Enter a title'
                        name="title"
                        value={newsData.title}
                        onChange={handleChange}
                        error={errors.title && Boolean(errors.title)}
                        helperText={errors.title && errors.title}
                    />
                </Box>
                <Box>
                    <DescField
                        placeholder="Description (1000 characters max)"
                        multiline
                        rows={12}
                        name="description"
                        value={newsData.description}
                        onChange={handleChange}
                        error={errors.description && Boolean(errors.description)}
                        helperText={errors.description && errors.description}
                    />
                </Box>
                <Box>
                    <Autocomplete
                        multiple
                        freeSolo
                        options={[].map((option) => option)}
                        name="tags"
                        value={newsData.tags}
                        onChange={handleTagChange}
                        renderInput={(params) => (
                            <TagInput
                                { ...params }
                                variant="outlined"
                                placeholder='Add upto 5 tags'
                                error={Boolean(errors.tags && errors.tags)}
                                helperText={errors.tags && errors.tags}
                            />
                        )}
                        renderTags={(value, getTagProps) => value.map((option, index) => (
                            <Chip variant='outlined' label={option} { ...getTagProps({ index })} onDelete={() => handleTagRemove(option)} />
                        ))}
                    />
                </Box>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant='contained'
                    disabled={submitting}
                    loading={submitting}
                >
                    {
                        (isCreateMode) ? "Add News" : "Update News"
                    }
                </LoadingButton>
            </form>
        </Box>
    )
}

export default NewsForm;
