import { Bar, Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend
} from 'chart.js';

import { Box, Card, CardContent, CircularProgress } from '@mui/material';

// Registering components required by Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

const Graph = (props) => {
  const { graph, loading, data, options } = props;

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <CircularProgress />
          ) : graph === 'bar' ? (
            <Bar data={data} options={options} />
          ) : (
            <Line data={data} options={options} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

Graph.propTypes = {
  graph: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.object,
  data: PropTypes.object,
};

export default Graph;
