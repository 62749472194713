import { PropTypes } from "prop-types";
import moment from "moment";

import { TableCell, TableRow } from "@mui/material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import UserInfoCellComponent from "../user/UserInfoCellComponent";

const tableConstants = () => {
  return [
    {
      title: 'ID',
      key: 'activityId',
    },
    {
      title: 'Agent',
      key: 'agent',
    },
    {
      title: 'Topic',
      key: 'topic',
    },
    {
      title: 'Date',
      key: 'date',
    },
  ];
};

const ActivityTable = (props) => {

    const { data } = {...props};
    return (
        <YMTableDesktop cols={tableConstants()}>
            {
              (!data || data.length === 0)
              ? <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              : data.map((activity, index) => (
                <TableRow key={index}>
                  <TableCell>
                      {activity.id}
                  </TableCell>
                  <TableCell>
                      <UserInfoCellComponent imageURL={activity.agent.fkImageIdAvatar} username={activity.agent.username} displayName={activity.agent.displayName} />
                  </TableCell>
                  <TableCell>
                      { activity.topic}
                  </TableCell>
                  <TableCell>
                      { moment(activity.createdAt).format('DD/MM/YYYY') }
                  </TableCell>
                </TableRow>
              ))
            }
        </YMTableDesktop>
    )
}

ActivityTable.propTypes = {
    data: PropTypes.array,
}

export default ActivityTable;