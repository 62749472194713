export const STICKER_SUPPORTED_IMAGE_FORMATS = [
  "image/png",
  "image/jpg",
  "image/jpeg",
];

export const STICKER_ALLOWED_IMAGE_MAX_SIZE = 51200;
export const STICKER_ALLOWED_IMAGE_MAX_SIZE_MESSAGE = `${
  STICKER_ALLOWED_IMAGE_MAX_SIZE / 1024
}kb`;
