import { useState, useEffect } from 'react';
import { Alert, Snackbar } from "@mui/material";
import { useAlertDispatch, useAlertState } from "/src/contexts/GlobalAlertContext";
import { HIDE_NOTIFICATION } from "/src/_helpers/alerts";

const Alerts = () => {

        const { show, type, message } = useAlertState();
        const { dispatch } = useAlertDispatch();

        const [open, setOpen] = useState(false);
        useEffect(() => {
                setOpen(show);
        }, [show]);

        const handleClose = () => {
                dispatch({ type: HIDE_NOTIFICATION });
        }

        return (
                <Snackbar
                        open={open}
                        onClose={handleClose}
                        autoHideDuration={3000}
                        anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                        }}
                >
                        <Alert severity={type} onClose={handleClose}>{message}</Alert>
                </Snackbar>
        );
}

export default Alerts;
