import { useEffect, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { getData } from "/src/utils/getData";
import { PropTypes } from "prop-types";
import moment from "moment";
import {
  TableCell,
  TableRow,
  Switch,
  IconButton,
  Box,
  Tooltip,
  Avatar,
  Chip,
  CircularProgress,
} from "@mui/material";
import { Visibility, DeleteForever } from "@mui/icons-material";

import { useModalDispatch } from "../../contexts/GlobalModalContext";
import { useAlertDispatch } from "/src/contexts/GlobalAlertContext";
import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import Loading from "../Loading";
import { setSponsoredPost } from "/src/api/query";
import { useGlobalMutation } from "/src/utils/useGlobalMutations";
import { isObjectEmpty } from "/src/utils/isObjectEmpty";
import { useCategory } from "/src/hooks/useCategory";

const tableConstants = () => {
  return [
    {
      title: "Meme",
      key: "postMeme",
    },
    {
      title: "Category",
      key: "category",
    },
    {
      title: "Title",
      key: "title",
    },
    {
      title: "Comments",
      key: "comments",
    },
    {
      title: "Reactions",
      key: "reactions",
    },
    {
      title: "Date",
      key: "createdAt",
    },
    {
      title: "Sponsored",
      key: "sponsored",
    },
    {
      title: "Action",
      key: "action",
    },
  ];
};

const PostsTable = () => {
  const dispatch = useModalDispatch();
  const { successAlert, errorAlert } = useAlertDispatch();
  const queryClient = useQueryClient();
  const setSponsored = useGlobalMutation(setSponsoredPost);

  const { getCategoryById, isLoading: categoryLoading } = useCategory();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [posts, setPosts] = useState([]);
  const [isLimit, setIsLimit] = useState(false);

  const { isLoading, data, fetchNextPage, refetch, isFetching, error } =
    useInfiniteQuery(["posts", offset, limit], () =>
      getData(`/post?offset=${offset}&limit=${limit}&sort=latest&type=public`)
    );

  useEffect(() => {
    if (data && data.pages[0].count > 0 && data.pages[0].count !== count)
      setCount(data.pages[0].count);
    if (data) setPosts([...posts, ...data.pages[0].data]);
  }, [data]);

  useEffect(() => {
    if (count > 0 && posts.length === count) setIsLimit(true);
  }, [posts]);

  useEffect(() => {
    if (offset < count) fetchNextPage(offset);
  }, [offset]);

  const postSposorshipSideEffects = (postId, sponsorship) => {
    queryClient.invalidateQueries("posts");
    queryClient.invalidateQueries("posts?onlySponsored=true");
    const sponsorshipStatus = sponsorship ? "sponsored" : "Unsponsored";
    successAlert(`Post ${postId} now ${sponsorshipStatus}`);
  };

  const setPostSponsorship = async (postId, sponsorship) => {
    const requestParamObject = { postId: postId, sponsor: sponsorship };

    try {
      await setSponsored.mutateAsync(requestParamObject);
      postSposorshipSideEffects(postId, sponsorship);
    } catch (error) {
      errorAlert("Something went wrong. Please try again.");
    }
  };

  const getMorePosts = () => {
    setOffset(offset + limit);
  };

  const getCategoryName = (id) => {
    return getCategoryById(id).name;
  };

  return (
    <YMTableDesktop
      cols={tableConstants()}
      paginated={false}
      infinite={true}
      showMore={getMorePosts}
      isLimit={isLimit}
      fetching={isFetching}
    >
      {posts && posts.length > 0 ? (
        posts.map((post, index) => (
          <TableRow key={index} hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Avatar
                  src={post.meme.renderedImage.urlOriginal}
                  sx={{ width: 56, height: 56 }}
                  variant='square'
                />
              </Box>
            </TableCell>
            <TableCell>
              {categoryLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Chip label={getCategoryName(post.fkCategoryId)} />
              )}
            </TableCell>
            <TableCell>{post.title}</TableCell>
            <TableCell>{post.nComments}</TableCell>
            <TableCell>
              {post.nLikes + post.nSuperlikes + post.nHots + post.nIntriguings}
            </TableCell>
            <TableCell>
              {moment(post.createdAt).format("DD/MM/YYYY")} <br />
              {moment(post.createdAt).format("HH:MM:SS")}
            </TableCell>
            <TableCell>
              <Switch
                checked={post.isSponsored}
                onChange={() => setPostSponsorship(post.id, !post.isSponsored)}
                colour='primary'
                name='SponsoredPostSwitch'
              />
            </TableCell>
            <TableCell>
              <Box display='flex' width='100px' justifyContent='space-between'>
                <Tooltip title='View Post on YouMeme'>
                  <IconButton
                    variant='contained'
                    color='primary'
                    size='large'
                    href={`${process.env.PARCEL_YOUMEME_FRONTEND_URL}/meme/${post.id}`}
                    target='_blank'
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Delete Post'>
                  <IconButton
                    variant='contained'
                    color='error'
                    size='large'
                    onClick={() =>
                      dispatch({ type: "DELETE_POST", value: post })
                    }
                  >
                    <DeleteForever />
                  </IconButton>
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={tableConstants().length}>
            {isLoading ? <Loading /> : <NoDataFound error={error} />}
          </TableCell>
        </TableRow>
      )}
    </YMTableDesktop>
  );
};

PostsTable.propTypes = {
  data: PropTypes.array,
};

export default PostsTable;
