import { localAxios } from "/src/api/axios";

/**
 * 
 * @param {Number} pageSize - size of data to be returned
 * @returns {Array} array of returned data
 */
export async function getAllUsers( pageSize = 10 ) {
    const returnData = [];
    let lastDataSize = 0;
    let page = 1;
    do {
      // add offset and limit to the endpoint; and then add extra query args if they are defined
      const fetchURL = `/user?page=${page}&limit=${pageSize}`;
      const res = await localAxios.get(fetchURL);
      if (res.status > 299 || res.status < 200) {
        const msg = `Response from API ${fetchURL} returned status ${res.status}`;
        console.error(msg);
        console.debug(res);
        throw new Error(msg);
      } else if (!res.data) {
        const msg = `Response from API ${fetchURL} contained no data`;
        console.error(msg);
        console.debug(res);
        throw new Error(msg);
      } else if (!Array.isArray(res.data.data)) {
        const msg = `Response from API ${fetchURL} did not have a data field of type array`;
        console.error(msg);
        console.debug(res);
        throw new Error(msg);
      } else {
        const newEntries = res.data.data;
        returnData.push(...newEntries);
        lastDataSize = newEntries.length;
        page++;
      }
    } while (lastDataSize)
    return returnData
  }
