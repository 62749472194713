import moment from "moment";

const date = new Date();
const year = moment(date).format('YYYY');
const today = moment(date);
// TODO: Replace moment with date-fn

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

Number.prototype.zeroPad = () => {
    return ('0'+this).slice(-2);
}

const getYearsForFilter = () => {
    let filterOptions = { title: 'Year' };
    filterOptions.items = Array.from({ length: 4 }, (_, i) => {
        let item = {};
        item.value = Number(year) - i;
        item.label = Number(year) - i;
        return item;
    });
    return filterOptions;
}

const getMonthsForFilter = (currentMonth) => {
    return months.filter(month => month <= currentMonth);
}

const getHoursForFilter = (currentHour) => {
    return hours.filter(hour => hour <= currentHour);
}

const getDaysForFilter = () => {
    let filterOptions = { title: 'Day' };
    filterOptions.items = Array.from({ length: 7 }, (_, i) => {
        let item = {};
        item.value = moment().subtract(i, "days").format('DD-MM-YYYY');
        if ( i === 0) {
            item.label = "Today";
        } else if ( i === 1) {
            item.label = "Yesterday";
        } else {
            item.label = item.value;
        }
        return item;
    })
    return filterOptions;
}

const daysSinceToday = (pastdate) => {
    const date = new Date(pastdate.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
    return today.diff(date, "days");
}

export { 
    getYearsForFilter,
    getHoursForFilter,
    getMonthsForFilter,
    getDaysForFilter,
    daysSinceToday
}