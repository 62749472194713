import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Divider,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import Page from "../components/Page";
import SubmissionsTable from "/src/components/events/CompetitionSubmissionTable";
import { useAlertDispatch } from "/src/contexts/GlobalAlertContext";
import { useModalDispatch } from "/src/contexts/GlobalModalContext";

const Submissions = () => {
  const dispatch = useModalDispatch();
  const location = useLocation();
  const { infoAlert } = useAlertDispatch();

  const eventId = location.pathname.split("/")[4];

  const [downloadSubmissions, setDownload] = useState(false);
  const [winners, setWinners] = useState([]);
  const [maxWinners, setMaxWinners] = useState(0);
  const [canAddWinners, toggleCanAddWinners] = useState(true);
  const [winnersClosed, setWinnersClosed] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const [disableToggle, toggleDisable] = useState(true);

  useEffect(() => {
    if (maxWinners && parseInt(winners?.length) === parseInt(maxWinners))
      toggleCanAddWinners(false);
    else toggleCanAddWinners(true);
  }, [winners]);

  useEffect(() => {
    if (!canAddWinners && !winnersClosed)
      infoAlert(
        "Max winners selected, click Submit Winners to review and add."
      );
  }, [canAddWinners]);

  const addWinner = (selectedWinners) => {
    if (selectedWinners) setWinners([...selectedWinners]);
  };

  const handleToggle = (event) => {
    setShowWinners(event.target.checked);
  };

  return (
    <Page title='Event Submissions | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            Event Submissions
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {`dashboad/events/submissions`}
          </Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            {
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showWinners}
                      onChange={handleToggle}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={disableToggle}
                    />
                  }
                  label='Show Winners'
                />
              </FormGroup>
            }
            {
              <Button
                variant='contained'
                color='info'
                sx={{
                  marginBottom: 1,
                  marginRight: 2,
                }}
                disabled={canAddWinners || winnersClosed}
                onClick={() =>
                  dispatch({
                    type: "CLOSE_EVENT_MODAL",
                    value: { id: eventId, data: winners },
                  })
                }
              >
                SUBMIT WINNERS
              </Button>
            }
            {
              <Button
                variant='contained'
                color='success'
                sx={{
                  marginBottom: 1,
                  marginRight: 2,
                }}
                onClick={() => setDownload(true)}
                disabled={downloadSubmissions}
              >
                EXPORT SUBMISSIONS
              </Button>
            }
            {
              <Link to='/dashboard/events/memecompetition'>
                <Button
                  variant='contained'
                  color='primary'
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  GO BACK
                </Button>
              </Link>
            }
          </Box>
        </Box>
        <Divider />
        <Box sx={{ pt: 3 }}>
          {
            <SubmissionsTable
              downloadSubmissions={downloadSubmissions}
              setDownload={(state) => setDownload(state)}
              setMaxWinners={(maxWinners) => setMaxWinners(maxWinners)}
              updateWinner={(winners) => addWinner(winners)}
              winnersSet={(state) => setWinnersClosed(state)}
              showWinners={showWinners}
              disableToggle={toggleDisable}
            />
          }
        </Box>
      </Container>
    </Page>
  );
};

export default Submissions;
