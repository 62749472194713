import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const SelectItems = (props) => {

  const { items, border, onChange, disabled } = { ...props };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      borderWidth: border ? 1 : 0,
      // width: 100,
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        borderColor: state.isFocused ? 'red' : 'blue'
      }
    })
  };

  return (
    <Select
      defaultValue={items[0]}
      isClearable={false}
      options={items}
      onChange={(item) => onChange(item.value)}
      styles={customStyles}
      components={{ IndicatorSeparator: () => null }}
      isDisabled={disabled}
    />
  );
};

SelectItems.propTypes = {
  items: PropTypes.array,
  border: PropTypes.bool,
  onChange: PropTypes.func
};

export default SelectItems;
