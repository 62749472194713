import { Box, Container, Typography, Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import Page from '../components/Page';
import AgentsTable from '../components/agents/AgentsTable';
import AgentsForm from '../components/forms/AgentsForm';

const Agents = ({ loadFormComponent }) => {
    return (
        <Page title="Agents | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Moderators
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {
                            (loadFormComponent)
                            ? "dashboard/agents/new"
                            : "dashboard/agents"
                        }
                    </Typography>
                    {
                        (loadFormComponent)
                        ? <Link to='/dashboard/agents'>
                            <Button variant="contained" color="primary" sx={{
                                marginBottom: 1
                            }}>GO BACK</Button>
                          </Link>
                        : <Link to='/dashboard/agents/new'>
                            <Button variant="contained" color="primary" sx={{
                                marginBottom: 1
                            }}>ADD MODERATOR</Button>
                          </Link>
                    }
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    {
                        (loadFormComponent)
                        ? <AgentsForm />
                        : <AgentsTable />
                    }
                </Box>
            </Container>
        </Page>
    );
}

export default Agents;