import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

const GlobalStyles = () => {
    const theme = useTheme();

    return (
        <GlobalThemeStyles
        styles={{
            '*': {
                margin: 0,
                padding: 0,
                boxSizing: 'border-box'
            },
            html: {
                width: '100%',
                height: '100%',
                WebkitOverflowScrolling: 'touch',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale', 
            },
            body: {
                width: '100%',
                height: '100%',
                backgroundColor: '#f4f6f8',
            },
            a: {
                textDecoration: 'none'
            },
            textarea: {
                '&::-webkit-input-placeholder': {
                  color: theme.palette.text.disabled
                },
                '&::-moz-placeholder': {
                  opacity: 1,
                  color: theme.palette.text.disabled
                },
                '&:-ms-input-placeholder': {
                  color: theme.palette.text.disabled
                },
                '&::placeholder': {
                  color: theme.palette.text.disabled
                }
            },
            '#root': {
                width: '100%',
                height: '100%'
            },
            '.center-to-middle': {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
            },

            // Lazy Load Img
            '.blur-up': {
            WebkitFilter: 'blur(5px)',
            filter: 'blur(5px)',
            transition: 'filter 400ms, -webkit-filter 400ms'
            },
            '.blur-up.lazyloaded ': {
            WebkitFilter: 'blur(0)',
            filter: 'blur(0)'
            }
        }}
        />
    );
}

export default GlobalStyles;