import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

const FlexBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
    height: 40
  });
  
const DescText = styled(Typography)({
    fontSize: 16,
    fontWeight: 'bold',
});

export {
    FlexBox,
    DescText
}