import { localAxios } from '../api/axios'

export const fetchData = async (endpoint) => {
  const res = await localAxios.get(endpoint, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    }
  })
  return res;
};

export const getData = async (endpoint) => {
  const { data } = await fetchData(endpoint);
  return data;
};