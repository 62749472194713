import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import logo from '/src/assets/images/logo.png';

const Logo = ({ sx }) => {
    return <Box component="img" src={logo} sx={{ width: '80px', ...sx }} />;
}

Logo.propTypes = {
    sx: PropTypes.object
};

export default Logo;
