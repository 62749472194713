import { LensClient, development, production } from '@lens-protocol/client';
import { switchChain, signText, getDefaultAddress } from './walletClient';

export const environment = process.env.PARCEL_LENS_ENV === 'mainnet' ? production : development;
const youmemeEndpoint = process.env.PARCEL_API_ENDPOINT
let youmemeAccessToken = '';


export const lensClient = new LensClient({
  environment
});


export const getProfiles = async (address) => {
  const allOwnedProfiles = await lensClient.profile.fetchAll({
    where: { ownedBy: [address] },
  })
  return allOwnedProfiles.items;
}

export const getDefaultProfileForWallet = async (ethAddress) => {
  const defaultProfile = await lensClient.profile.fetchDefault({
    for: ethAddress
  })
  if (defaultProfile != null) {
    return defaultProfile.id
  } else {
    throw new Error(`No default profile found for wallet ${ethAddress}`);
  }
}

export const getAccessToken = async () => {
  const token = await lensClient.authentication.getAccessToken()
  if (token.isFailure()) {
    const errToken = token.unwrap()
    console.error(errToken)
    return ''
  } else if (token.isSuccess()) {
    const accessToken = token.unwrap()
    return accessToken
  } else {
    console.error('Unknown error fetching access token')
    return ''
  }
}

/**
 * This method logs into Lens and YouMeme using the provided address and profileId.
 * @param {string} address evm address of the user
 * @param {string} profileId profile id of the user
 */
export const login = async (address, profileId) => {
  
  await switchChain();
  if (address == null || address === '') {
    console.warn('Login: no addresss provided; using default address')
    address = await getDefaultAddress();
    console.warn(`Login: using default address ${address}`);
  }
  if (profileId == null || profileId === '') {
    console.warn('Login: no profileId provided; using default profileId')
    profileId = await getDefaultProfileForWallet(address);
    console.warn(`Login: using default profileId ${profileId}`);
  }
  console.info(`Attempting to login to Lens and YouMeme with address ${address} and profileId ${profileId}`);
  const challengeRes = await lensClient.authentication.generateChallenge({
    signedBy: address,
    for: profileId,
  });
  const challengeId = challengeRes.id;
  const challengeText = challengeRes.text;
  console.info(`Challenge generated with id ${challengeId} and text ${challengeText}`);
  const signedChallenge = await signText(address, challengeText);
  console.info(`Challenge signed with signature ${signedChallenge}`);
  await lensClient.authentication.authenticate({
    id: challengeId,
    signature: signedChallenge,
  });
  console.info(`Successfully authenticated with Lens`);
  const lensAccessToken = await getAccessToken();
  console.info(`Received lens access token ${lensAccessToken}`)
  const youmemeLoginRes = await fetch(`${youmemeEndpoint}/auth/complete-login?source=lens`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      token: lensAccessToken,
    }),
  });
  const youmemeLoginData = await youmemeLoginRes.json();
  console.debug(youmemeLoginData);
  if (youmemeLoginRes.status != 200) {
    console.error(youmemeLoginData)
    throw new Error(`Error logging in to YouMeme; check console for more info`);
  }
  // FIXME at this point we've logged into Lens AND YouMeme but we are only using
  // the YouMeme login to authenticate with the backend so we can send requests to it
  youmemeAccessToken = youmemeLoginData.accessToken;
}
