import { styled } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const Wrapper = styled(FormControl)(() => ({
  color: "red",
  backgroundColor: "white",
  width: "100%",
  minWidth: 120,
  borderRadius: 5,
  marginBottom: 24,
  fontSize: 16,
}));

const SelectField = ({ value, onChange, options, label, labelId, name }) => {
  return (
    <Wrapper variant='outlined'>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={name}
        value={value}
        label={label}
        onChange={onChange}
        name={name}
      >
        {options?.map(({ value, label }, index) => {
          return (
            <MenuItem value={value} key={index.toString()}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </Wrapper>
  );
};

export default SelectField;
