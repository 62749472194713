import { useState , useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';

import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { useAuthState } from '/src/contexts/UserAuthContext';
import sidebarConfig from "/src/layouts/dashboard/SidebarConfig";
import pageNotFoundBanner from "/src/assets/images/undraw_page_not_found_su7k.svg";

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
}));

const Page404 = () => {
    const { userLoggedIn, user } = useAuthState();

    const [toPath, setToPath] = useState('/');
    useEffect(() => {
        if (userLoggedIn && user && user.role) {
            for (let i = 0; i < sidebarConfig.length; i++) {
                if (sidebarConfig[i].roles.includes(user.role)) {
                    if (sidebarConfig[i].children && sidebarConfig[i].children.length > 0) {
                        for (let j = 0; j < sidebarConfig[i].children.length; j++) {
                            if (sidebarConfig[i].children[j].roles.includes(user.role)) {
                                setToPath(sidebarConfig[i].children[j].path);
                                break;
                            }
                        }
                        break;
                    } else {
                        setToPath(sidebarConfig[i].path);
                        break;
                    }
                }
            }
        } else {
            setToPath('/');
        }
    }, []);

    return (
        <RootStyle title="404 Not Found | YM Admin">
            <Container>
                <MotionContainer initial="initial" open>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragrapg>
                                404: The page you are looking for is not here.
                            </Typography>
                        </motion.div>
                        <Typography sx={{ color: 'text.secondary' }}>
                            Sorry, could not find the page. Be sure to check the spelling. If you are trying something shady, let us know if you find anything. 
                        </Typography>
                        <motion.div variants={varBounceIn}>
                            <Box
                                component="img"
                                src={pageNotFoundBanner}
                                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }} />
                        </motion.div>

                        <Button size="large" to={toPath} variant="contained" component={RouterLink}>
                            Go to Home
                        </Button>
                    </Box>
                </MotionContainer>
            </Container>
        </RootStyle>
    );
}

export default Page404;
