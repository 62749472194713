import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

import { useAuthState, useAuthDispatch } from '/src/contexts/UserAuthContext';

const ErrorText = styled(Typography)({
    textAlign: 'center',
    border: '1px solid #FB9AA4',
    padding: '10px',
    zIndex: '1',
    background: '#FFDCE0',
    borderRadius: '50px',
    marginTop: '20px'
});

const SuccessText = styled(Typography)({
    textAlign: 'center',
    border: '1px solid #4BB543',
    padding: '10px',
    zIndex: '1',
    background: '#4BB543',
    borderRadius: '50px',
    marginTop: '20px'
});

const LoginForm = () => {

    const { loginError, loginMessage } = useAuthState();
    const { logIn, handleLoginError } = useAuthDispatch();
    const [ hasSubmit, toggleSubmit ] = useState(false);
    const [ counter, setCounter ] = useState();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            toggleSubmit(true);
            logIn(values.email);
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    useEffect(() => {
        setCounter(60);
        if (hasSubmit) {
            setTimeout(() => {
                toggleSubmit(false);
            }, 1 * 60 * 1000)
        }
    }, [hasSubmit])

    useEffect(() => {
        const timer = hasSubmit && counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter])

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>                
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email address"
                        { ...getFieldProps('email') }
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={hasSubmit}
                    sx={{
                        marginTop: "10px"
                    }}
                >
                    {
                    (hasSubmit) 
                    ?
                        `Resend email in 60 seconds`
                    :
                        'Login'
                    }
                </LoadingButton>
                {
                    (loginError)
                    ? <ErrorText>Error: {loginError}</ErrorText>
                    : (loginMessage) 
                        ? <SuccessText>{loginMessage}</SuccessText>
                        : ''
                }
            </Form>
        </FormikProvider>
    );
}

export default LoginForm;
