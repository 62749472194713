import PropTypes from 'prop-types';
import { Card, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useQuery } from 'react-query';
import { getStats } from '/src/api/stats';

const RootStyle = styled(Card)({
  height: 195,
  borderRadius: 10,
  padding: 20,
  position: 'relative'
});

const TitleText = styled(Typography)({
  color: 'white',
  fontSize: '1vw'
});

const ErrorText = styled(Typography)({
  fontsize: '1vw',
  color: 'white',
  zIndex: 1,
  position: 'absolute',
  backgroundColor: 'red',
  padding: '0 10px 0 10px'
})

const CntText = styled(Typography)({
  color: 'white',
  fontSize: '2vw',
  fontFamily: 'Poppins Semibold',
  lineHeight: '55px',
  zIndex: 1,
  position: 'absolute'
});

const CntTextSmall = styled(Typography)({
  color: 'white',
  fontSize: '0.8vw',
  fontFamily: 'Poppins Semibold',
  lineHeight: '55px',
  zIndex: 1,
  position: 'absolute'
});

const BackgroundImage = styled('img')({
  position: 'absolute',
  bottom: 0,
  right: 0
});

const DataCard = (props) => {
  const {
    color, title, type, img
  } = props;

  const { isLoading, error, data } = useQuery(['stats', type], () => getStats(type));

  return (
    <RootStyle style={{ backgroundColor: color }}>
      <TitleText>{title}</TitleText>
      {(isLoading) ? (
        <CircularProgress sx={{
            position: 'absolute',
            zIndex: 1,
            left: '45%',
            top: '45%'
        }} />
        ) : (error)
            ? <ErrorText>Oops, unable to fetch data at the moment.</ErrorText>
            : <>
                <CntText>
                {`${data.cnt ?? 0.0}(${data.percentageLast24h?.toFixed(1) ?? 0.0}%)`}
                </CntText> <br/>
                <CntTextSmall mt={2}>
                  {`${data.cntLast24h} ${type} in last 24 hours`}
                </CntTextSmall>
              </>
      }
      <BackgroundImage src={img} alt="statistics data" />
    </RootStyle>
  );
};

DataCard.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  img: PropTypes.string
};

export default DataCard;
