import { useMutation, useQueryClient } from 'react-query'


export const useGlobalMutation = (mutationFn) => {
  const myMutation = useMutation(mutationFn)
  return myMutation;
}

export const useBanUserMutation = (mutationFn) => {
  const queryClient = useQueryClient();
  const {mutate} = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('banuser')
    },
  })
  return mutate
}
