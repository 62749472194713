import { getAuthData, getSelfUserId } from "./self";
import { getAllCategories } from "./category";
import { getAllEvents } from "./events";
import { getAllUsers } from "./user";
import { getAddresses, switchChain, walletClient, signText } from "./walletClient";
import { environment, lensClient, getProfiles, getDefaultProfileForWallet, login, getAccessToken } from "./lens";
import { localAxios } from "/src/api/axios";

// If ymca.js is imported from the main App.js/App.ts, it attaches a
// YMCA object to window, which can help debug data fetching from the
// backend.

const YMCA = {
    util: {
        localAxios
    },
    self: {
        getAuthData,
        getSelfUserId
    },
    category: {
        getAllCategories
    },
    users: {
        getAllUsers
    },
    events: {
        getAllEvents
    },
    wallet: {
        walletClient,
        switchChain,
        getAddresses,
        signText
    },
    lens: {
        environment,
        lensClient,
        getProfiles,
        getDefaultProfileForWallet,
        login,
        getAccessToken
    }
}

window.ymca = YMCA;
