const getImageBase64DataUri = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader?.result);
  };

  reader.onerror = function (error) {
    console.log(error);
  };
};

export default getImageBase64DataUri;
