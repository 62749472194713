import React from 'react';
import useContextFactory from './context.util';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION, INFO_NOTIFICATION, HIDE_NOTIFICATION } from '../_helpers/alerts';

var AlertStateContext = React.createContext();
var AlertDispatchContext = React.createContext();

const initAlertState = {
  show: false,
  message: '',
  type: ''
}

const alertReducer = (state, action) => {
    switch(action.type) {
        case ERROR_NOTIFICATION:
          return {
            ...state,
            show: true,
            type: 'error',
            message: action.value
          }
        case SUCCESS_NOTIFICATION:
          return {
            ...state,
            show: true,
            type: 'success',
            message: action.value
          }
        case INFO_NOTIFICATION:
          return {
            ...state,
            show: true,
            type: 'info',
            message: action.value
          }
        case HIDE_NOTIFICATION:
          return {
            ...state,
            show: false,
            message: ''
          }
        default: {
          throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const GlobalAlertProvider = ({ children }) => {
  var [state, dispatch] = React.useReducer(alertReducer, initAlertState);

  return (
    <AlertStateContext.Provider value={state}>
      <AlertDispatchContext.Provider value={dispatch}>
        { children }
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  )
}

const useAlertState = () => useContextFactory(AlertStateContext);
const useAlertDispatch = () => {
    const dispatch = useContextFactory(AlertDispatchContext);

    const successAlert = (message) => {
        dispatch({ type: SUCCESS_NOTIFICATION, value: message });
    }

    const errorAlert = (message) => {
        dispatch({ type: ERROR_NOTIFICATION, value: message });
    }

    const infoAlert = (message) => {
        dispatch({ type: INFO_NOTIFICATION, value: message });
    }

    return { dispatch, successAlert, errorAlert, infoAlert };
}

export {
  GlobalAlertProvider,
  useAlertState,
  useAlertDispatch
}