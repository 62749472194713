import { useEffect, useState } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ValueInput from "./ValueInput";
import DescField from "./DescField";
import FileUpload from "./FileUpload";
import { useGlobalMutation } from "/src/utils/useGlobalMutations";
import { addEvent, editEvent, getUser, uploadEventImage } from "/src/api/query";
import {
  useErrorAction,
  useSuccessAction,
} from "/src/utils/mutationResponseAction";
import useUserSearch from "/src/utils/useUserSearch";
import { Check, Search } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlertDispatch } from "/src/contexts/GlobalAlertContext";
import diff from "/src/utils/getObjectDiff";
import { useQueryClient } from "react-query";

const EventForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCreateMode = !location.state;

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
  ];

  // Calls the action that will upload the image in edit mode
  const uploadEventImageAction = useGlobalMutation(uploadEventImage);

  // Button state
  const [submitting, toggleSubmit] = useState(false);

  //Internal State
  const [eventData, setEventData] = useState({
    name: null,
    description: null,
    urlWebsite: null,
  });
  const [eventDate, setEventDate] = useState({
    startingDate: {
      formattedDate: null,
      returnedDate: null,
    },
    endingDate: {
      formattedDate: null,
      returnedDate: null,
    },
  });
  const [eventImage, setEventImage] = useState({
    previewImage: null,
    raw: null,
  });
  const [eventImageFormat, setEventImageFormat] = useState(null);
  const [errors, setErrors] = useState({
    name: null,
    urlWebsite: null,
    imageSize: null,
    imageFormat: null,
    email: null,
    description: null,
  });
  const [email, setEmail] = useState();
  const [fkUserId, setfkUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [isUser, updateIsUser] = useState(false);

  useEffect(() => {
    if (!isCreateMode) {
      setEventImage((prev) => ({
        ...prev,
        previewImage: location.state.image.urlOriginal,
      }));
      // Fetch user using location.state.fkUserId
      // Set fkUserId, email, isUser=true
      getUser(location.state.fkUserId)
        .then((response) => {
          setEmail(response.data?.data[0]?.email);
          setfkUserId(location.state.fkUserId);
          updateIsUser(true);
        })
        .catch((error) => {
          errorAlert(`Error fetching event owner details.`);
        });
      // Set name, description, urlWebsite
      setEventData({
        name: location.state.name,
        urlWebsite: location.state.urlWebsite
          ? location.state.urlWebsite
          : null,
        description: location.state.description
          ? location.state.description
          : null,
      });
      // Set dates
      setEventDate({
        startingDate: {
          formattedDate: new Date(location.state.startingDate).toISOString(),
          returnedDate: new Date(location.state.startingDate),
        },
        endingDate: {
          formattedDate: new Date(location.state.startingDate).toISOString(),
          returnedDate: new Date(location.state.endingDate),
        },
      });
    }
  }, []);

  //Calls the action that will perform the add event action and update the event list
  const addEventAction = useGlobalMutation(addEvent);

  // Calls the action that will perform the edit event action and update the event list
  const editEventAction = useGlobalMutation(editEvent);

  // Dispatch actions for each alert
  const { successAlert, errorAlert } = useAlertDispatch();

  //actions the mutations above will perform for each state.
  const errorAction = useErrorAction();
  const successAction = useSuccessAction();

  const queryClient = useQueryClient();

  const validateImageFormat = (type) => {
    const isValid = SUPPORTED_FORMATS.includes(type);
    setErrors((prev) =>
      isValid
        ? { ...prev, imageFormat: null }
        : { ...prev, imageFormat: "Image format is not supported" }
    );
    return isValid;
  };

  const validateImageSize = (size) => {
    const approvedImageSizeInBytes = 3000000;
    let isValid = false;

    if (size < approvedImageSizeInBytes) {
      setErrors((prev) => ({ ...prev, imageSize: null }));
      isValid = true;
    } else {
      setErrors((prev) => ({ ...prev, imageSize: "Image is too large!" }));
    }
    return isValid;
  };

  const validateImage = (file) => {
    const isSizeValid = validateImageSize(file.size);
    const isFormatValid = validateImageFormat(file.type);

    return isSizeValid && isFormatValid;
  };

  const validate = (name, value) => {
    switch (name) {
      case "name":
        const namePattern = /^[^^*|":<>[\]{}`\\();@&$]+$/g;
        const isValid = namePattern.test(value);
        setErrors((prev) =>
          isValid
            ? { ...prev, name: null }
            : {
                ...prev,
                name: "Name should not be empty or contain special characters.",
              }
        );
        const isValidLength = value.length < 70;
        setErrors((prev) =>
          isValidLength
            ? { ...prev, name: null }
            : { ...prev, name: "Event name should be less than 70 characters." }
        );
        break;

      case "urlWebsite":
        const urlPattern =
          /[(http(s)?):\\/(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
        const isURLValid = urlPattern.test(value);
        setErrors((prev) =>
          isURLValid
            ? { ...prev, urlWebsite: null }
            : { ...prev, urlWebsite: "Please enter a valid URL" }
        );
        break;

      case "email":
        const emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,20}$/;
        const isEmailValid = emailPattern.test(value);
        setErrors((prev) =>
          isEmailValid
            ? { ...prev, email: null }
            : { ...prev, email: "Please enter a valid email address" }
        );
        if (isEmailValid) setSearchQuery(value);
        break;

      case "description":
        const isCorrectLength = value.length < 5000;
        setErrors((prev) =>
          isCorrectLength
            ? { ...prev, description: null }
            : {
                ...prev,
                description: "Description should be less than 5000 characters.",
              }
        );
        break;

      default:
        break;
    }
  };

  const handleChange = (e) => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
    validate(e.target.name, e.target.value);
  };

  const handleDateChange = (date, name) => {
    setEventDate({
      ...eventDate,
      [name]: {
        formattedDate: date.toISOString(),
        returnedDate: date,
      },
    });
  };

  const handleFileUpload = (e) => {
    let file = e.target.files[0];
    let format = file.type.replace("image/", "");
    let fileValid = validateImage(file);
    setEventImage({
      previewImage: URL.createObjectURL(file),
      raw: file,
    });
    setEventImageFormat(format);

    // sends image to server if in edit mode and valid
    if (!isCreateMode && fileValid) {
      toggleSubmit(true);
      uploadEventImageAction.mutate(
        {
          id: location.state.id,
          image: {
            type: format,
            size: file.size,
          },
          data: file,
        },
        {
          onSuccess: () => {
            successAlert(`Event Image Set!`);
            toggleSubmit(false);
          },
          onError: (error) => {
            errorAlert(error.message);
            toggleSubmit(false);
          },
        }
      );
    }
  };

  const preSumbit = (values) => {
    let initialValues = {
      name: location.state.name,
      description: location.state.description,
      urlWebsite: location.state.urlWebsite,
      startingDate: new Date(location.state.startingDate).toISOString(),
      endingDate: new Date(location.state.endingDate).toISOString(),
      userId: location.state.fkUserId,
    };

    let finalValues = values;
    finalValues = diff(initialValues, values);
    return finalValues;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", eventData.name);
    formData.append("urlWebsite", eventData.urlWebsite);
    formData.append("startingDate", eventDate.startingDate.formattedDate);
    formData.append("endingDate", eventDate.endingDate.formattedDate);
    formData.append("userId", fkUserId);
    formData.append("description", eventData.description);

    if (isCreateMode) {
      formData.append("eventImageFormat", eventImageFormat);
      formData.append("eventImage", eventImage.raw);
      toggleSubmit(true);
      let fileValid = validateImage(eventImage.raw);
      if (
        fileValid &&
        errors.name == null &&
        errors.urlWebsite == null &&
        fkUserId
      ) {
        toggleSubmit(true);
        addEventAction.mutate(formData, {
          onSuccess: (data, variables, contexts) => {
            successAction("events", "/dashboard/events/main", "Event Added");
            toggleSubmit(false);
          },
          onError: (error, variables, contexts) => {
            errorAction(error.message);
            toggleSubmit(false);
          },
        });
      }
    } else {
      const editedData = preSumbit({
        name: eventData.name,
        urlWebsite: eventData.urlWebsite,
        description: eventData.description,
        startingDate: eventDate.startingDate.formattedDate,
        endingDate: eventDate.endingDate.formattedDate,
        userId: fkUserId,
      });
      toggleSubmit(true);
      editEventAction.mutate(
        {
          id: location.state.id,
          data: editedData,
        },
        {
          onSuccess: (data) => {
            successAlert(`Event Updated`);
            queryClient.setQueryData(
              ["events", { id: location.state.id }],
              data
            );
            toggleSubmit(false);
            navigate("/dashboard/events/main");
          },
          onError: (error) => {
            toggleSubmit(false);
            errorAlert(error.message);
          },
        }
      );
    }
  };

  // Effects
  useEffect(() => {
    if (email) {
      validate("email", email);
    }
  }, [email]);

  // Search
  const searchResult = useUserSearch(searchQuery);

  useEffect(() => {
    if (
      searchResult.data &&
      searchResult.data.data &&
      searchResult.data.data.length > 0
    ) {
      setErrors((prev) => ({ ...prev, email: null }));
      updateIsUser(true);
      setfkUserId(searchResult.data.data[0].id);
      return;
    }

    if (searchResult.error) {
      setErrors((prev) => ({
        ...prev,
        email: "Unable to find user, please verify email.",
      }));
    }
  }, [searchResult]);

  const handleKeyDown = (e) => {
    if (e.code === "Enter") validate("email", email);
  };

  return (
    <Box
      sx={{
        maxWidth: "md",
      }}
    >
      <Box>
        <ValueInput
          label='Sponsoring User'
          type='email'
          placeholder='Seach user by email'
          name='email'
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email && Boolean(errors.email)}
          helperText={errors.email && errors.email}
          onKeyDown={(e) => handleKeyDown(e)}
          InputProps={{
            endAdornment: !isUser ? (
              <InputAdornment position='end'>
                <Search
                  onClick={() => validate("email", email)}
                  sx={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ) : (
              <InputAdornment position='end'>
                <Check />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
        }}
      >
        <FileUpload
          required
          name='eventImage'
          value={eventImage}
          accept={SUPPORTED_FORMATS.join(", ")}
          onImageChange={handleFileUpload}
        />
        <Box
          sx={{
            ml: 5,
          }}
        >
          <Typography color='#313131' fontSize='16px'>
            Upload Thumbnail
          </Typography>
          <Typography color='#828282' fontSize='14px'>
            80px &times; 80px at Max 3MB
          </Typography>

          <Typography color='#ff0000'>
            {errors.imageSize && errors.imageSize}
          </Typography>
          <Typography color='#ff0000'>
            {errors.imageFormat && errors.imageFormat}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: 200,
          my: 3,
          "& img": {
            objectFit: "cover",
            width: "100%",
            height: "100%",
          },
        }}
      >
        {eventImage.previewImage ? (
          <img src={eventImage.previewImage} alt='preview' />
        ) : null}
      </Box>
      <form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Box>
          <ValueInput
            variant='outlined'
            required
            placeholder='Enter a name for event'
            name='name'
            value={eventData.name}
            onChange={handleChange}
            error={errors.name && Boolean(errors.name)}
            helperText={errors.name && errors.name}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label='Start Date'
              name='startingDate'
              value={eventDate.startingDate.returnedDate}
              allowSameDateSelection
              inputFormat='dd/MM/yyyy hh:mm a'
              onChange={(date) => handleDateChange(date, "startingDate")}
              minDateTime={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.startingDate && Boolean(errors.startingDate)}
                  helperText={errors.startingDate && errors.startingDate}
                  sx={{
                    flexGrow: "1",
                    marginRight: ".5em",
                    backgroundColor: "#ffffff",
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label='End Date'
              name='endingDate'
              value={eventDate.endingDate.returnedDate}
              allowSameDateSelection
              inputFormat='dd/MM/yyyy hh:mm a'
              onChange={(date) => handleDateChange(date, "endingDate")}
              minDateTime={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.endingDate && Boolean(errors.endingDate)}
                  helperText={errors.endingDate && errors.endingDate}
                  sx={{
                    flexGrow: "1",
                    marginLeft: ".5em",
                    backgroundColor: "#ffffff",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <ValueInput
            variant='outlined'
            placeholder='Enter a url for event (https://example.com)'
            type='url'
            name='urlWebsite'
            value={eventData.urlWebsite}
            onChange={handleChange}
            error={errors.urlWebsite && Boolean(errors.urlWebsite)}
            helperText={errors.urlWebsite && errors.urlWebsite}
          />
        </Box>
        <Box>
          <DescField
            placeholder='Description (1000 characters max)'
            multiline
            rows={4}
            name='description'
            value={eventData.description}
            onChange={handleChange}
            error={errors.description && Boolean(errors.description)}
            helperText={errors.description && errors.description}
          />
        </Box>
        <LoadingButton
          size='large'
          type='submit'
          variant='contained'
          disabled={submitting}
          loading={submitting}
        >
          {isCreateMode ? "Add Event" : "Update Event"}
        </LoadingButton>
      </form>
    </Box>
  );
};

export default EventForm;
