import { PropTypes } from "prop-types";
import moment from "moment";
import { useQuery } from "react-query";
import { getData } from "/src/utils/getData";

import { TableCell, TableRow, IconButton, Box, Chip, Tooltip } from "@mui/material";
import { Edit, DeleteForever } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import UserInfoCellComponent from "../user/UserInfoCellComponent";
import Loading from '../Loading';

import { useModalDispatch } from '../../contexts/GlobalModalContext';
import { useNavigate } from "react-router-dom";

const tableConstants = () => {
  return [
    {
      title: 'Moderator Details',
      key: 'agent',
    },
    {
      title: 'Email',
      key: 'email',
    },
    {
      title: 'Role',
      key: 'role',
    },
    {
      title: 'Created At',
      key: 'createdAt',
    },
    {
        title: 'Action',
        key: 'action',
    }
  ];
};

const AgentsTable = (props) => {

    const dispatch = useModalDispatch();
    const navigate = useNavigate();

    const { isLoading, data, error } = useQuery(['agents'], () => getData('/user?role=admin,superadmin,moderator,analyst,event,news&sort=latest'));

    const handleAgentEdit = (agent) => {
      navigate('/dashboard/agents/edit', { state: agent });
    }

    const handleAgentDelete = (agent) => {
      const body = {
        displayName: agent.displayName,
        imageURL: agent.fkImageIdAvatar,
        username: agent.username,
        email: agent.email,
        role: 'user'
      }
      dispatch({ type: 'DELETE_AGENT', value: body })
    }

    return (
        <YMTableDesktop cols={tableConstants()} paginated={false}>
            {
              (data && data.data && data.data.length > 0)
              ? data.data.map((agent, index) => (
                <TableRow key={index} hover>
                  <TableCell>
                      <UserInfoCellComponent displayName={agent.displayName} imageURL={agent.avatar?.urlOriginal} username={agent.username} />
                  </TableCell>
                  <TableCell>
                      {agent.email}
                  </TableCell>
                  <TableCell>
                    <Chip label={agent.role.charAt(0).toUpperCase() + agent.role.slice(1)} variant="outlined" />
                  </TableCell>
                  <TableCell>
                    { moment(agent.createdAt).format('DD/MM/YYYY') } <br />
                    { moment(agent.createdAt).format('HH:MM:SS')}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" width="100px" justifyContent="space-between">
                      <Tooltip title="Edit Agent">
                        <IconButton variant="contained" color="primary" size="large" onClick={() => handleAgentEdit(agent)}><Edit /></IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Agent">
                        <IconButton variant="contained" color="error" size="large" onClick={() => handleAgentDelete(agent)}><DeleteForever /></IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
              : <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    {
                      (isLoading)
                        ? <Loading />
                        : <NoDataFound error={error} />
                    }
                  </TableCell>
                </TableRow>
            }
        </YMTableDesktop>
    )
}

AgentsTable.propTypes = {
    data: PropTypes.array,
}

export default AgentsTable;
