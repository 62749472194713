import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";

import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Chip,
} from "@mui/material";
import { useAuthState } from "/src/contexts/UserAuthContext";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: "12px",
  height: 48,
  borderRadius: "10px",
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const { user } = useAuthState();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, disabled, roles } = item;
  const [open, setOpen] = useState(isActiveRoot);

  useEffect(() => {
    if (!isActiveRoot) setOpen(false);
  }, [isActiveRoot]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.activatedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    if (roles.includes(user.role)) {
      return (
        <>
          <ListItemStyle
            onClick={handleOpen}
            sx={{
              ...(isActiveRoot && activeRootStyle),
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            {info && info}
            <Box
              component={Icon}
              icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
              sx={{ width: 16, height: 16, ml: 1 }}
            />
          </ListItemStyle>

          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {children.map((item) => {
                const { title, path, roles } = item;
                const isActiveSub = active(path);

                if (roles.includes(user.role)) {
                  return (
                    <ListItemStyle
                      key={title}
                      component={RouterLink}
                      to={path}
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                      }}
                    >
                      <ListItemIconStyle>
                        <Box
                          component='span'
                          sx={{
                            width: 4,
                            height: 4,
                            display: "flex",
                            borderRadius: "50%",
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: "text.disabled",
                            transition: (theme) =>
                              theme.transitions.create("transform"),
                            ...(isActiveSub && {
                              transform: "scale(2)",
                              bgcolor: "primary.main",
                            }),
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Collapse>
        </>
      );
    } else {
      return null;
    }
  }

  if (roles.includes(user.role)) {
    return (
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
        disabled={disabled ? disabled : false}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText
          disableTypography
          primary={title}
          secondary={
            disabled ? (
              <Chip
                label='Coming Soon'
                color='error'
                size='small'
                variant='outlined'
                sx={{
                  fontSize: "8px",
                  fontWeight: 800,
                  ml: "2px",
                  padding: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              />
            ) : (
              ""
            )
          }
        />
        {info && info}
      </ListItemStyle>
    );
  } else return null;
}

const NavSection = ({ navConfig, ...other }) => {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
};

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default NavSection;
