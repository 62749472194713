import { useQuery } from "react-query";
import { getData } from "/src/utils/getData";
import moment from "moment";

import { TableCell, TableRow, IconButton, Box } from "@mui/material";
import { Block } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import Loading from '../Loading';

const tableConstants = () => {
  return [
    {
      title: 'ID',
      key: 'tagId',
    },
    {
      title: 'Tag Name',
      key: 'tagName',
    },
    {
      title: 'Created',
      key: 'createdAt',
    },
    {
        title: 'Action',
        key: 'action',
    }
  ];
};

const TagsTable = () => {

    const { isLoading, data, error } = useQuery(['tags'], () => getData('/admin/tags'));

    return (
        <YMTableDesktop cols={tableConstants()} paginated={false}>
            {
              (data && data.data && data.data.length > 0)
              ? data.data.map((tag, index) => (
                <TableRow key={index}>
                  <TableCell>
                      {tag.id}
                  </TableCell>
                  <TableCell>
                    {tag.name.toUpperCase()}
                  </TableCell>
                  <TableCell>
                    { moment(tag.createdAt).format('DD/MM/YYYY') }
                  </TableCell>
                  <TableCell>
                    <Box display="flex" width="100px" justifyContent="space-between">
                      <IconButton variant="outlined"  color="warning" size="large" disabled><Block /></IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
              : <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    {
                      (isLoading)
                        ? <Loading />
                        : <NoDataFound error={error} />
                    }
                  </TableCell>
                </TableRow>
            }
        </YMTableDesktop>
    )
}

export default TagsTable;
