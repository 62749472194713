import {localAxios} from './axios';


/**
 * @type {Map<string, string>}
 */
const imageMimeTypes = new Map();
imageMimeTypes.set('png', 'image/png');
imageMimeTypes.set('jpeg', 'image/jpeg');
imageMimeTypes.set('jpg', 'image/jpeg');
imageMimeTypes.set('svg', 'image/svg+xml');
imageMimeTypes.set('webp', 'image/webp');
imageMimeTypes.set('gif', 'image/gif');

/**
 * Auth Region
 */

/**
 * Users Region
 */
/**
 * 
 * @param {string} id of the user to delete
 * @returns {Promise}
 */
 export const deleteUser = async (payload) => {
    return localAxios.delete(`/admin/users/${payload.id}`)
}

export const banUser = async (user) => {
    return localAxios.post(`/admin/banuser`, user)
}

export const unbanUser = async (user) => {
    return localAxios.delete(`/admin/banuser/${user.id}`)
}

export const sponserUser = async (payload) => {
    return localAxios.put(`/admin/users/${payload.id}/verification?certified=${!payload.isVerified}`)
}

export const getUser = async (id) => {
    return localAxios.get(`user?id=${id}`)
}

/**
 * Publications Region
 */
/**
 * 
 * @param {string} id of the post to delete ( Also used to to delete news )
 * @returns {Promise}
 */
 export const deletePost = async ( payload ) => {
    return localAxios.delete(`/post/${payload.id}`)
}
/**
 * 
 * @param {object} newsInfo - the data to use to create the news post
 * @param {string} newsInfo title
 * @param {string} newsInfo description
 * @param {array string} newsInfo tags
 * @param {string} newsInfo type ( defaults to news )
 * @param {object} newsInfo.source image to upload
 * @param {string} newsInfo.sourceImageFormat format of the image to upload
 * @returns {Promise}
 */
export const addNews = async (newsInfo) => {
    return localAxios.put(`/post`, newsInfo)
}
/**
 * Edit event
 */
export const editNews = async (payload) => {
    return localAxios.patch(`/post/${payload.id}`, payload.data)
}
/**
 * Upload news image
 */
export const uploadNewsImage = async (payload) => {
    return localAxios.put(`/post/${payload.id}/image`, payload.data, {
        headers: {
            "Content-Type": imageMimeTypes.get(payload.image.type),
        },
        params: {
            fmt: payload.image.type, size: payload.image.size
        },
    });
}
/**
 * 
 * @param {string} postId 
 * @returns {Promise}
 */
 export const setSponsoredPost = async (payload) => {
    return localAxios.put(`/admin/posts/sponsored/${payload.postId}?sponsor=${payload.sponsor}`)
}


/**
 * Agents Region
 */
export const updateUserRole = async (payload) => {
    return localAxios.put(`/admin/users/roles`, payload)
}

/**
 * Category and Tags Region
 */

/**
 * addCategory invokes the add category admin API
 * It expects that categoryInfo is a valid formdata object
 * categoryInfo needs to have a name, decription, tags, and image.
 * @param {object} categoryinfo 
 * @param {string} authToken 
 * @param {boolean} autoFetchAuthToken
 * @returns {Promise}
 */
export const addCategory = async (categoryinfo) => {
    return localAxios.put(`/admin/category`, categoryinfo)
}

export const getCategories = async () => {
    return localAxios.get(`/category`);
}

export const deleteCategory = async (payload) => {
    return localAxios.delete(`/admin/category/${payload.id}`)
}
/**
 * 
 * @param {object} payload
 * @param {string} payload.id id of the category being edited
 * @param {string} payload.description description of the category
 * @param {array} payload.tags list  of selected tags 
 * @returns 
 */
export const editCategory = async (payload) => {
    return localAxios.patch(`/admin/category/${payload.id}`, payload.data,{
        params: {
            id: payload.id
        }
    })
}

/**
 * 
 * @param {object} payload upload payload
 * @param {string} payload.id id of the category to upload image to
 * @param {object} payload.image image to upload
 * @param {string} payload.image.type type of the image
 * @param {number} payload.image.size size of the image in bytes
 * @param {buffer} payload.data the actual image that is being uploaded
 * @returns {Promise}
 */
export const uploadCategoryImage = async (payload) => {
    return localAxios.put(`/admin/category/${payload.id}/image`, payload.data, {
        headers: {
            "Content-Type": imageMimeTypes.get(payload.image.type),
        },
        params: {
            fmt: payload.image.type, size: payload.image.size
        },
    });
}
/**
 * Events Region
 */
/**
 * 
 * @param {object} eventinfo - the data to use to create the event
 * @param {string} eventinfo name
 * @param {string} eventinfo startDate date and time
 * @param {string} eventinfo endDate date and time
 * @param {object} eventinfo.eventImage image to upload
 * @returns {Promise}
 */
export const addEvent = async (eventinfo) => {
    return localAxios.post(`/event`, eventinfo)
}
/**
 * 
 * @param {string} id of the event to delete
 * @returns {Promise}
 */
export const deleteEvent = async (payload) => {
    return localAxios.delete(`/event/${payload.id}`)
}

/**
 * 
 * @param {string} id 
 * @param {string} postId
 * @returns {Promise}
 */


export const deleteWinningPost = async (payload) => {
    return localAxios.delete(`/event/${payload.fkEventId}/winner/${payload.id}`)
}

export const uploadEventImage = async (payload) => {
    return localAxios.put(`/event/${payload.id}/image`, payload.data, {
        headers: {
            "Content-Type": imageMimeTypes.get(payload.image.type),
        },
        params: {
            fmt: payload.image.type, size: payload.image.size
        },
    });
}

export const editEvent = async (payload) => {
    return localAxios.patch(`/event/${payload.id}`, payload.data)
}

export const finalizeEventWinners = async (payload) => {
    let winners = payload.data.map((each) => {
        return {
            postId: each.postId,
            rank: each.rank,
        }
    })
    return localAxios.put(`/event/${payload.id}/winner`, { winners })
}

/**
 * Maintenance Region : #TODO
 */
/**
 * Activity Region : #TODO
 */
