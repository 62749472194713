import { Box, Container, Typography, Divider } from '@mui/material';

import Page from '../components/Page';
import Filter from '../components/common/Filter';
import BannedUserTable from '/src/components/user/BannedUserTable';
import { getDaysForFilter } from '/src/utils/getFilters';

const BannedUserList = () => {
    const filterOptions = getDaysForFilter();
    return (
        <Page title="Banned Users | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Users
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        dashboard/userban
                    </Typography>
                    <Filter { ...filterOptions } disabled={true} />
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    <BannedUserTable />
                </Box>
            </Container>
        </Page>
    );
}

export default BannedUserList;
