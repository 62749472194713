import { styled } from '@mui/system';
import { TextField } from '@mui/material'

const DescField = styled(TextField)(
    () => ({
        backgroundColor: 'white',
        borderRadius: 5,
        resize: 'none',
        width: '100%',
        fontFamily: 'Poppins',
        fontSize: 16,
        boxSizing: 'border-box',
        marginBottom: 24,
        overflowY: 'auto',
        outline: 'none'
    })
);

export default DescField;