import { getData } from "/src/utils/getData";
import { localAxios } from "../axios";

/**
 * @type {Map<string, string>}
 */
const imageMimeTypes = new Map();
imageMimeTypes.set("png", "image/png");
imageMimeTypes.set("jpeg", "image/jpeg");
imageMimeTypes.set("jpg", "image/jpeg");
imageMimeTypes.set("svg", "image/svg+xml");
imageMimeTypes.set("webp", "image/webp");
imageMimeTypes.set("gif", "image/gif");

/**
 * Auth Region
 */

/**
 * Temp Region
 */

/**
 * @description Delete Template Query
 * @param {string} id of the template to be deleted
 * @returns {Promise}
 */
export const deleteTemplate = async (payload) => {
  return localAxios.delete(`/meme-template/${payload.id}`);
};

/**
 * @description Delete Template Query
 * @param {string} id of the template to be deleted
 * @returns {Promise}
 */
export const getTemplate = async (templateId) => {
  // return localAxios.get(`/meme-template?id=${templateId}`);
  return getData(`/meme-template?id=${templateId}`);
};

/**
 * @description Edit Template
 * @param {String} payload.title
 * @param {String} payload.description
 * @param {Number} payload.sortPosition
 * @param {Boolean} payload.isPublic
 * @param {Boolean} payload.isNsfw
 * @param {Array<String>} payload.tags
 */
export const editTemplate = async (payload) => {
  return localAxios.patch(`/meme-template/${payload.id}`, payload.data);
};

/**
 * @description Create Template
 * @param {String} payload.title
 * @param {String} payload.description
 * @param {Number} payload.sortPosition
 * @param {Boolean} payload.isPublic
 * @param {Boolean} payload.isNsfw
 * @param {Array<String>} payload.tags
 * @param {String} payload.templateImage
 */
export const createTemplate = async (payload) => {
  return localAxios.post(`/meme-template`, payload.data);
};
