import { Box, Container, Typography, Divider } from '@mui/material';

import Page from '../components/Page';
import TagsTable from '../components/categoryandtags/TagsTable';

const Tags = () => {
    return (
        <Page title="Tags | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Tags
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        dashboard/tags
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    <TagsTable />
                </Box>
            </Container>
        </Page>
    );
}

export default Tags;