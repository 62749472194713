import { Box, Container, Typography, Divider, Button } from '@mui/material';

import Page from '../components/Page';
import MaintenanceTable from '../components/maintenance/MaintenanceTable';

const Maintenance = () => {
    return (
        <Page title="Maintenance | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Maintenance
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        dashboard/maintenance
                    </Typography>
                    <Button variant="contained" color="primary" sx={{
                        marginBottom: 1
                    }} disabled>ADD MAINTENANCE</Button>
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    <MaintenanceTable />
                </Box>
            </Container>
        </Page>
    );
}

export default Maintenance;