import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, Chip, Grid } from '@mui/material';
import { styled } from '@mui/system';

const CustomTableHead = styled(TableHead)({
    '& th': {
      color: 'white',
      backgroundColor: '#816AD6 !important'
    }
})

const YMTableDesktop = ({children, ...props}) => {
    const { cols, count, page, limit, handlePageChange, handleLimitChange, showMore, paginated=true, infinite=false, isLimit=false, fetching=true } = { ...props };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader>
                        <CustomTableHead>
                            <TableRow hover>
                                {
                                    cols.map((headerItem, index) => {
                                        return <TableCell key={index}>
                                            { headerItem.title }
                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        </CustomTableHead>
                        <TableBody>
                            { children }
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    (paginated && count && Number(count) > limit)
                        ? <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={count}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={(event, page) => handlePageChange(page + 1)}
                            onRowsPerPageChange={(e) => handleLimitChange(e.target.value)}
                        />
                        : ''
                }
            </Paper>
            <Grid container direction="column" marginTop={2} justifyContent="center">
                <Grid item textAlign="center">                    
                    {
                        (fetching) 
                            ? ''
                            : (infinite)
                                ? (!isLimit) 
                                    ? <Chip label='Show More' color='primary' onClick={showMore} /> 
                                    : <Chip label='Nothing more to show' color='error'/>
                                : ''
                    }
                </Grid>
            </Grid>
        </>
    )
}

YMTableDesktop.propTypes = {
    cols: PropTypes.array,
}

export default YMTableDesktop;