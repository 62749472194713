import { styled , TextField } from '@mui/material'

const ValueInput = styled(TextField)(
    () => ({
      color: '#313131',
      backgroundColor: 'white',
      width: '100%',
      borderRadius: 5,
      marginBottom: 24,
      fontSize: 16
    })
);

export default ValueInput;