import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getData } from "/src/utils/getData";
import moment from "moment";

import { TableCell, TableRow, IconButton, Box, Avatar, Tooltip, Chip, Badge } from "@mui/material";
import { ContentCopy, DeleteForever, MilitaryTech, RemoveCircle, Visibility } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import Loading from '../Loading';

import { useModalDispatch } from "/src/contexts/GlobalModalContext";
import { useLocation, useParams } from "react-router-dom";
import UserInfoCellComponent from "../user/UserInfoCellComponent";
import { useAuthState } from "/src/contexts/UserAuthContext";
import { Roles } from "/src/_helpers/role";
import { useAlertDispatch } from "/src/contexts/GlobalAlertContext";

const tableConstants = () => {
  return [
    {
      title: 'Meme',
      key: 'postMeme',
    },
    {
      title: 'User',
      key: 'postUser',
    },
    {
      title: 'Email/Address',
      key: 'userEmail',
    },
    {
      title: '# Comments',
      key: 'comments',
    },
    {
      title: '# Reactions',
      key: 'reactions',
    },
    {
      title: 'Date',
      key: 'date',
    },
    {
      title: 'Actions',
      key: 'action',
    }
  ];
};

const SubmissionsTable = ({ downloadSubmissions, setDownload, updateWinner, setMaxWinners, winnersSet, showWinners, disableToggle }) => {

  const dispatch = useModalDispatch();
  const { successAlert, errorAlert } = useAlertDispatch();
  const { user } = useAuthState();
  const { eventId } = useParams();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);


  const [winningPosts, setWinningPosts] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [winnersSelected, setWinnersSelected] = useState(false);
  const [count, setCount] = useState(0);

  const [postsToShow, setPostsToShow] = useState([]);

  const fetchData = async (offset) => {
    let data = await getData(`/post?offset=${offset}&limit=100&eventId=${eventId}&type=competition`);
    if (data && data.data) return data.data;
    return false;
  }

  const fetchWinningPosts = async (winningPostsIds) => {
    let data = await getData(`/post?offset=0&limit=100&id=${winningPostsIds}`);
    if (data && data.data) return data.data;
    return [];
  }

  useEffect(async () => {
    if (downloadSubmissions && count > 0) {
      // Fetch all responses in a loop, with max limit.
      const counter = Math.floor(count / 100) + 1;
      let allFetched = true;
      let allSubmissions = [];
      for (let i = 0; i < counter; i++) {
        let entries = await fetchData(i * 100);
        if (!entries) {
          allFetched = false;
          errorAlert("Unable to fetch all posts. Please try again later.");
          break;
        }
        allSubmissions = [...allSubmissions, ...entries];
      }
      if (!allFetched) return;
      let csvContent = 'Meme URL,Title,User Name,User Id,User Email/Lens Address,Reward Address,User Profile,Total Reactions,Total Comments,Date Published\n';
      allSubmissions.map((post) => {
        csvContent += process.env.PARCEL_YOUMEME_FRONTEND_URL + '/meme/' + post.id + ',';
        csvContent += post.title + ',';
        csvContent += post.user.displayName + ',';
        csvContent += post.user.username.toLowerCase() + ',';
        csvContent += (post.user.email) ? post.user.email : post.user.ethAddress;
        csvContent += ',';
        csvContent += post.user.rewardEthAddress + ',';
        csvContent += process.env.PARCEL_YOUMEME_FRONTEND_URL + '/profile/' + post.user.username.toLowerCase() + ',';
        csvContent += (post.nLikes + post.nSuperlikes + post.nHots + post.nIntriguings).toString() + ',';
        csvContent += post.nComments.toString() + ',';
        csvContent += moment(post.createdAt).format('DD/MM/YYYY') + ',';
        csvContent += '\n'
      })
      console.info(csvContent);
      // Create a hidden link
      let hiddenLink = document.createElement('a');
      hiddenLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
      hiddenLink.target = '_blank';
      hiddenLink.download = 'Contest Submissions.csv';
      hiddenLink.click();
      setDownload(false);
    }
  }, [downloadSubmissions])

  const { isLoading: preDataLoading, data: preData, error: preDataError } = useQuery(['posts', 'count', eventId], () => getData(`/post?eventId=${eventId}&type=competition&limit=0`));

  useEffect(() => {
    if (preData) {
      setCount(preData.count);
      if (preData.count === 0) setDownload(true);
    }
  }, [preDataLoading, preData, preDataError])

  useEffect(() => {
    if (page === 1 && offset === 0) return;
    setOffset((page - 1) * limit);
  }, [page]);

  const { isLoading, data, error } = useQuery(['posts', offset, limit], () => getData(`/post?offset=${offset}&limit=${limit}&eventId=${eventId}&type=competition`), {
    refetchOnWindowFocus: false,
  });

  const { data: event } = useQuery(['event', eventId], () => getData(`/event?eventId=${eventId}&expired=true&type=competition`), { refetchOnMount: false });

  const isWinningPost = (id) => {
    if (winningPosts && winningPosts.length > 0) {
      return winningPosts.filter(winningPost => winningPost.postId === id);
    }
    return false;
  }

  useEffect(() => {
    if (!event || !data) return;
    if (event && event.data) {
      setMaxWinners(event.data[0].eventMetadata.contestDetail.maxWinners);
      if (event.data[0].eventMetadata.contestWinners && event.data[0].eventMetadata.contestWinners.length === Number(event.data[0].eventMetadata.contestDetail.maxWinners)) {
        setWinnersSelected(true);
      }
      setCurrentEvent(event.data[0]);
      if (event.data[0].eventMetadata.contestWinners) {
        disableToggle(false);
        let currentWinningPosts = event.data[0].eventMetadata.contestWinners;
        let currentFormattedWinningPosts = currentWinningPosts.map((each, index) => {
          return {
            ...each,
            rank: (each.rank) ? each.rank : index + 1,
            post: data.data[data.data.findIndex((post) => post.id === each.postId)]
          }
        })
        setWinningPosts(currentFormattedWinningPosts);
      }
    }
    if (data) setPostsToShow(data.data);
  }, [event, data]);

  useEffect(async () => {
    if (showWinners) {
      let winningPostsOnly = winningPosts.map((post) => post.postId).join(',');
      let fullWinningPosts = await fetchWinningPosts(winningPostsOnly);
      setPostsToShow(fullWinningPosts);
    } else if (data) {
      setPostsToShow(data.data)
    }
  }, [showWinners])

  useEffect(() => {
    winnersSet(winnersSelected);
  }, [winnersSelected])

  useEffect(() => {
    updateWinner(winningPosts);
  }, [winningPosts])

  const addPostToWinners = (post) => {
    let currentWinningPosts = winningPosts
    currentWinningPosts.push({ post: post, rank: winningPosts.length + 1, postId: post.id });
    setWinningPosts([...currentWinningPosts]);
  }

  const removePostWinner = (postToBeRemoved) => {
    if (user.role === Roles.EVENT_MANAGER && winnersSelected) {
      errorAlert('Winners cannot be edited once selected. Please reach the admin to make changes.');
      return;
    }
    if (winnersSelected) setWinnersSelected(false);
    let postToBeRemovedRank = winningPosts.filter((post) => post.postId === postToBeRemoved.id)[0].rank;
    let currentWinningPosts = winningPosts.filter((post) => post.postId !== postToBeRemoved.id);
    let rankedPosts = currentWinningPosts.map((post) => {
      if (post.rank > postToBeRemovedRank) {
        return {
          ...post,
          rank: post.rank - 1
        }
      } else {
        return post;
      }
    });
    setWinningPosts([...rankedPosts]);
  }

  const copyToClipboard = (item) => {
    navigator.clipboard.writeText(item);
    successAlert("Successfully copied address to clipboard");
  }

  return (
    <YMTableDesktop cols={tableConstants()} count={(showWinners) ? (postsToShow.length) : (data) ? Number(data.count) : 0} page={page - 1} limit={limit} handlePageChange={(page) => setPage(Number(page))} handleLimitChange={(limit) => setLimit(Number(limit))}>
      {
        (data && data.data && data.data.length > 0)
          ? postsToShow.map((post, index) => (
            <TableRow key={index}>
              <TableCell>
                <Box sx={{
                  alignItems: 'center',
                  display: 'flex'
                }}>
                  <Badge
                    overlap='circular'
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={
                      (isWinningPost(post.id).length > 0)
                        ?
                        <Chip label={isWinningPost(post.id)[0].rank} sx={{ ml: 2 }} color='warning' />
                        : ''
                    }
                  >
                    <Avatar src={post.meme.renderedImage.urlOriginal} sx={{ width: 56, height: 56 }} variant='square' />
                  </Badge>
                </Box>
              </TableCell>
              <TableCell>
                <UserInfoCellComponent imageURL={post.user.avatar?.urlOriginal} username={post.user.username.toLowerCase()} displayName={post.user.displayName} isBanned={false} />
              </TableCell>
              <TableCell>
                {post.user.email} <br />
                {post.user.rewardEthAddress}
                {
                  (post.user.rewardEthAddress)
                    ?
                    <Tooltip title="User reward address, click to copy address">
                      <IconButton variant="contained" color="primary" size="small" onClick={() => copyToClipboard(post.user.rewardEthAddress)}>
                        <ContentCopy fontSize="8" />
                      </IconButton>
                    </Tooltip>
                    : ""
                }
              </TableCell>
              <TableCell>
                {post.nComments}
              </TableCell>
              <TableCell>
                {post.nLikes + post.nSuperlikes + post.nHots + post.nIntriguings}
              </TableCell>
              <TableCell>
                {moment(post.createdAt).format('DD/MM/YYYY')} <br />
                {moment(post.createdAt).format('HH:MM:SS')}
              </TableCell>
              <TableCell>
                <Box display="flex" width="100px" justifyContent="space-between">
                  <Tooltip title="View Post on YouMeme">
                    <IconButton variant="contained" color="primary" size="large" href={`${process.env.PARCEL_YOUMEME_FRONTEND_URL}/meme/${post.id}`} target="_blank"><Visibility /></IconButton>
                  </Tooltip>
                  {
                    (isWinningPost(post.id).length > 0)
                      ?
                      <Tooltip title="Undo as Winner">
                        <IconButton variant="outlined" color="error" size="large" onClick={() => removePostWinner(post)} disabled={new Date(currentEvent.endingDate) > Date.now()}>
                          <RemoveCircle />
                        </IconButton>
                      </Tooltip>
                      :
                      <Tooltip title={
                        (new Date(currentEvent.endingDate) > Date.now())
                          ? "Event in progress"
                          : (parseInt(currentEvent?.eventMetadata?.contestDetail?.maxWinners) === winningPosts?.length)
                            ? "Max winners selected"
                            : "Select Post As Winner"
                      }>
                        <span>
                          <IconButton variant="outlined" color="warning" size="large" onClick={() => addPostToWinners(post)} disabled={parseInt(currentEvent?.eventMetadata?.contestDetail?.maxWinners) === winningPosts?.length || new Date(currentEvent?.endingDate) > Date.now()}>
                            <MilitaryTech />
                          </IconButton>
                        </span>
                      </Tooltip>
                  }
                  <Tooltip title="Delete Post">
                    <IconButton variant="contained" color="error" size="large" onClick={() => dispatch({ type: 'DELETE_POST', value: post })}><DeleteForever /></IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))
          : <TableRow>
            <TableCell colSpan={tableConstants().length} >
              {
                (isLoading)
                  ? <Loading />
                  : <NoDataFound error={error} />
              }
            </TableCell>
          </TableRow>
      }
    </YMTableDesktop>
  )
}

export default SubmissionsTable;
