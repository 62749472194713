import { useEffect, useState } from 'react';

import Graph from "../graph/Graph";
import Filter from '../common/Filter';
import { FlexBox, DescText } from '../common/Styled';

import { getDaysForFilter, getHoursForFilter } from '../../utils/getFilters';
import { useQueries } from 'react-query';
import { getPostsByDateRange, getUsersByDateRange } from '/src/api/stats';

const currentHour = new Date().getHours();
const currentDate = new Date().getDate();

const HourlyCountLineChart = () => {
    
    // Get filter options
    const filterOptions = getDaysForFilter();
    const [dateRanges, setDateRanges] = useState([]);

    // Set init data
    const [day, setDay] = useState(filterOptions.items[0].value.split('-'));
    let data = {};

    useEffect(() => {
      let hours;
      let ranges = [];
      if (day[0] === currentDate) {
        hours = getHoursForFilter(currentHour);
      } else {
        hours = getHoursForFilter(23);
      }
      for (let eachHour in hours) {
        let startTime = new Date(day[2], day[1] - 1, day[0], eachHour, '00', '00').getTime();
        let endTime = new Date(day[2], day[1] - 1, day[0], eachHour, '59', '59').getTime();
        ranges.push(
          {
            startTime,
            endTime
          }
        )
        setDateRanges(ranges);
      }
      
    }, [day]);

    // Style the graph
    const options = {
      responsive: true,
      scales: {
        x: {
          ticks: {
            maxRotation: 30,
            minRotation: 30
          },
          grid: {
            display: true,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            display: true,
            beginAtZero: true,
            min: 0,
          },
          grid: {
            display: true
          }
        }
      },
    };    

    const userResults = useQueries(
      dateRanges.map((range) => {
        return {
          queryKey: ['users', range.startTime, range.endTime], 
          queryFn: () => getUsersByDateRange(range.startTime, range.endTime),
          enabled: !!(dateRanges.length > 0)
        }
      })
    )

    const postResults = useQueries(
      dateRanges.map((range) => {
        return {
          queryKey: ['posts', range.startTime, range.endTime], 
          queryFn: () => getPostsByDateRange(range.startTime, range.endTime),
          enabled: !!(dateRanges.length > 0)
        }
      })
    )

    const isLoading = userResults.filter((result) => result.isLoading).length !== 0 && postResults.filter((result) => result.isLoading).length !== 0;

    if (!isLoading) {
        data = {
            ...data,
            labels: dateRanges.map((date) => String(new Date(date.startTime).getHours()).padStart(2, '0') + ':00'),
            datasets: [
                {
                    type: 'line',
                    label: 'User Activity',
                    borderColor: '#707070',
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 7,
                    pointBackgroundColor: 'white',
                    data: userResults.map(result => result.data?.count),
                },
                {
                    type: 'bar',
                    label: 'Meme Activity',
                    backgroundColor: '#FFC727',
                    borderColor: 'white',
                    borderWidth: 2,
                    data: postResults.map(result => result.data?.count),
                }
            ]
        }
    }

    return (
        <>
            <FlexBox>
                <DescText>User activity per hour </DescText>
                <Filter { ...filterOptions } handleChange={(value) => setDay(value.split('-'))} />
            </FlexBox>
            <Graph graph="bar" data={data} options={options} loading={isLoading} />
        </>
    )
}

export default HourlyCountLineChart;
