import { TextField } from "@mui/material";

const Search = ({ title, onChange, disabled }) => {
    return (
        <TextField
            name={title + "-search"}
            onChange={(event) => onChange(event.target.value)}
            label={title}
            variant="standard"
            sx={{
                padding: 0,
                marginX: 0,
            }}
            disabled={disabled}
        />
    );
}

export default Search;