import { Box, Container, Typography, Divider, Button } from "@mui/material";

import Page from "../components/Page";
import CategoryTable from "../components/categoryandtags/CategoryTable";
import CategoryForm from "../components/forms/CategoryForm";
import { Link, useLocation } from "react-router-dom";

const Category = ({ loadFormComponent }) => {
  const location = useLocation();

  return (
    <Page title='Category | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            Category
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {location.pathname}
          </Typography>
          {loadFormComponent ? (
            <Link to='/dashboard/categoryandtags/category'>
              <Button
                variant='contained'
                color='primary'
                sx={{
                  marginBottom: 1,
                }}
              >
                GO BACK
              </Button>
            </Link>
          ) : (
            <Link to='/dashboard/categoryandtags/category/new'>
              <Button
                variant='contained'
                color='primary'
                sx={{
                  marginBottom: 1,
                }}
              >
                ADD CATEGORY
              </Button>
            </Link>
          )}
        </Box>
        <Divider />
        <Box sx={{ pt: 3, justifyContent: "center" }}>
          {loadFormComponent ? <CategoryForm /> : <CategoryTable />}
        </Box>
      </Container>
    </Page>
  );
};

export default Category;
