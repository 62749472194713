import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const Loading = () => {
    return (
        <Box>
            {/* <img src="/src/assets/images/logo-circle.svg" alt="" /> */}
            <CircularProgress sx={{
                marginLeft: "50%"
            }}/>
        </Box>
    )
}

export default Loading
