import { useEffect, useState } from "react";
import { useQueryClient, useQuery, useQueries } from "react-query";
import { getData } from "/src/utils/getData";
import moment from "moment";

import { TableCell, TableRow, Switch, Box, Avatar, Typography, Chip } from "@mui/material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import Loading from '../Loading';
import UserInfoCellComponent from "../user/UserInfoCellComponent";

import { useAlertDispatch } from "/src/contexts/GlobalAlertContext";
import { useGlobalMutation } from "/src/utils/useGlobalMutations";

import { setSponsoredPost } from "/src/api/query";

const tableConstants = () => {
  return [
    {
      title: 'Post Id',
      key: 'id',
    },
    {
      title: 'Category',
      key: 'category',
    },
    {
      title: 'Title',
      key: 'title',
    },
    {
      title: 'User',
      key: 'user',
    },
    {
      title: 'Date',
      key: 'createdAt',
    },
    {
        title: 'Action',
        key: 'action',
    }
  ];
};

const SponsoredPostsTable = () => {

    const {successAlert, errorAlert} = useAlertDispatch();
    const queryClient = useQueryClient();
    const setSponsored = useGlobalMutation(setSponsoredPost);

    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryMap, updateCategoryMap] = useState(new Map());

    useEffect(() => {
        if (page === 1 && offset === 0) return;
        setOffset((page - 1) * limit);
    })
    
    
    const { isLoading: postsLoading, data: posts, error: postsError } = useQuery(['posts', 'sponsored'], () => getData(`/post?onlySponsored=true&offset=${offset}&limit=${limit}`));
    
    useEffect(() => {
      if (posts?.data.length > 0) {
        posts.data.map((post) => {
          setCategoryList([...categoryList, post.fkCategoryId])
        })
      }
    }, [posts]);

    const categoriesQuery = useQueries(categoryList.map(categoryId => {
      return {
        queryKey: ['category', categoryId],
        queryFn: () => getData(`/category?id=${categoryId}`),
        enabled: categoryList.length > 0,
        onSuccess: (data) => updateCategoryMap(categoryMap.set(categoryId, data.data[0])),
      }
    }));

    const categoriesIsLoading = categoriesQuery.filter((result) => result.isLoading).length !== 0;

    const postSposorshipSideEffects = (postId, sponsorship) => {
      queryClient.invalidateQueries('posts');
      queryClient.invalidateQueries('posts?onlySponsored=true');
      const sponsorshipStatus = sponsorship ? 'sponsored' : 'Unsponsored';
      successAlert(`Post ${postId} now ${sponsorshipStatus}`);
    }

    const unsponsorPost = async (postId, sponsor = false) => {
      const requestParamObject = {postId: postId, sponsor: sponsor};

      try{
        await setSponsored.mutateAsync(requestParamObject);
        postSposorshipSideEffects(postId, sponsor);
      }
      catch(error){
        errorAlert("Something went wrong. Please try again.");
      }
    }

    return (
        <YMTableDesktop cols={tableConstants()} paginated={true} count={(posts) ? Number(posts.count) : 0} page={page - 1} limit={limit} handlePageChange={(page) => setPage(Number(page))} handleLimitChange={(limit) => setLimit(Number(limit))}>
            {
              (posts && !categoriesIsLoading && posts.data && posts.data.length > 0)
              ? posts.data.map((post, index) => (
                <TableRow key={index} hover>
                  <TableCell>{post.id}</TableCell>
                  <TableCell>
                    <Box
                          sx={{
                              alignItems: 'center',
                              display: 'flex'
                          }}
                      >
                          <Avatar
                          src={categoryMap.get(post.fkCategoryId)?.image.urlOriginal}
                          sx={{ mr: 2 }}
                          />
                          <Typography
                          color="textPrimary"
                          variant="body1"
                          >
                              { categoryMap.get(post.fkCategoryId)?.name.toUpperCase() }
                          </Typography>
                      </Box>
                  </TableCell>
                  <TableCell>
                    {post.title}
                  </TableCell>
                  <TableCell>
                    {
                      (post.user) ?
                        <UserInfoCellComponent imageURL={post.user?.avatar?.imageURL} username={post.user?.username} displayName={post.user?.displayName} />
                        :
                        <Chip label="User removed." color="error" />
                    }
                  </TableCell>
                  <TableCell>
                    { moment(post.createdAt).format('DD/MM/YYYY') }<br/>
                    { moment(post.createdAt).format('HH:MM:SS')}
                  </TableCell>
                  <TableCell>
                    <Switch
                        checked={(post.isSponsored) ? post.isSponsored : false}
                        onChange={() => unsponsorPost(post.id)}
                        color="primary"
                        name="verificationSwitch"
                      />
                  </TableCell>
                </TableRow>
              ))
              : <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    {
                      (postsLoading || categoriesIsLoading)
                        ? <Loading />
                        : <NoDataFound error={postsError} />
                    }
                  </TableCell>
                </TableRow>
            }
        </YMTableDesktop>
    )
}

export default SponsoredPostsTable;
