import { Box, Container, Typography, Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import MemeCompetitionTable from '/src/components/events/MemeCompetitionTable';
import MemeCompetitionForm from '/src/components/forms/MemeCompetitionForm';
import { useAuthState } from '/src/contexts/UserAuthContext';
import { Roles } from '/src/_helpers/role';
import Page from '../components/Page';

const MemeCompetition = ({ loadFormComponent }) => {

    const { user } = useAuthState();

    return (
        <Page title="Meme Competition Events | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Meme Competition Events
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {
                            (loadFormComponent)
                            ? "dashboard/events/memecompetition/new"
                            : "dashboad/events/memecompetition"
                        }
                    </Typography>
                    {
                        (loadFormComponent)
                        ? <Link to='/dashboard/events/memecompetition'>
                            <Button variant="contained" color="primary" sx={{
                                marginBottom: 1
                            }}>GO BACK</Button>
                          </Link>
                        : (user.role === Roles.EVENT_MANAGER)
                            ? ''
                            :
                            <Link to='/dashboard/events/memecompetition/new'>
                                <Button variant="contained" color="primary" sx={{
                                    marginBottom: 1
                                }}>ADD NEW COMPETITION</Button>
                            </Link>
                    }
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    {
                        (loadFormComponent)
                        ? <MemeCompetitionForm />
                        : <MemeCompetitionTable />
                    }
                </Box>
            </Container>
        </Page>
    );
}

export default MemeCompetition;
