import { getData } from '/src/utils/getData';

const getStats = (endpoint, year='', month='', day='') => getData(`/statistics/${endpoint}?year=${year}&month=${month}&day=${day}`);

const getYearlyStats = (endpoint, year) => getData(`/statistics/${endpoint}?year=${year}`);
const getMonthlyStats = (endpoint, year, month) => getData(`/statistics/${endpoint}?year=${year}&month=${month}`);
const getDailyStats = (endpoint, year, month, day) => getData(`/statistics/${endpoint}?year=${year}&month=${month}&day=${day}`);
const getHourlyStats = (endpoint, year, month, day, hour) => getData(`/statistics/${endpoint}?year=${year}&month=${month}&day=${day}&hour=${hour}`)

// New endpoints
const getUsersByDateRange = (startDate, endDate) => getData(`/user?limit=0&joinedBefore=${endDate}&joinedAfter=${startDate}&sort=latest`);
const getPostsByDateRange = (startDate, endDate) => getData(`/post?limit=0&startTime=${startDate}&endTime=${endDate}&sort=latest&type=public,competition`);

export {
    getStats,
    getYearlyStats,
    getMonthlyStats,
    getDailyStats,
    getHourlyStats,
    getUsersByDateRange,
    getPostsByDateRange,
}
