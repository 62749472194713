import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import componentOverride from './overrides';
import shadows, { customShadows } from './shadows';

const ThemeConfig = ({ children }) => {
    const themeOptions = useMemo(
        () => ({
            palette,
            typography,
            shadows,
            customShadows
        }),
        []
    );

    const theme = createTheme(themeOptions);
    theme.components = componentOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                { children }
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeConfig.propTypes = {
    children: PropTypes.node
};

export default ThemeConfig;