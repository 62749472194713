/**
 * 
 * @param {Object} selfData 
 * @returns {Object}
 */
export function validateSelfData(selfData) {
    if (typeof selfData.accessToken !== 'string') {
      console.debug('validating selfData:', selfData);
      throw new Error(`validateSelfData: typeof accessToken is not string`);
    }
    if (typeof selfData.user !== 'object') {
      console.debug('validating selfData:', selfData);
      throw new Error(`validateSelfData: typeof token is not string`);
    }
    const userData = selfData.user;
    if (typeof userData.id !== 'string') {
      console.debug('validating selfData:', selfData);
      throw new Error(`validateSelfData: typeof token is not string`);
    }
    if (typeof userData.email !== 'string') {
      console.debug('validating selfData:', selfData);
      throw new Error(`validateSelfData: typeof email is not string`);
    }
    if (typeof userData.username !== 'string') {
      console.debug('validating selfData:', selfData);
      throw new Error(`validateSelfData: typeof username is not string`);
    }
    if (typeof userData.displayName !== 'string') {
      console.debug('validating selfData:', selfData);
      throw new Error(`validateSelfData: typeof displayName is not string`);
    }
    if (typeof userData.role !== 'string') {
        console.debug('validating selfData:', selfData);
        throw new Error(`validateSelfData: typeof role is not string`);
    }
    return selfData;
}

/**
 * 
 * @returns {Object} validated data of current user
 */
export async function getAuthData() {
    const serializedSelf = window.localStorage.getItem('user');
    if (!serializedSelf) {
      throw new Error(`localStorage.user was not found!`);
    }
    let selfData;
    try {
      selfData = JSON.parse(serializedSelf);
    } catch (err) {
      const msg = `localStorage.user was not valid JSON. We found ${serializedSelf} instead.`;
      console.debug(msg);
      throw new Error(msg);
    }
    const validatedData = validateSelfData(selfData);
    return validatedData;
  }
 
/**
 * 
 * @returns {string} id of current user
 */
export async function getSelfUserId() {
    const selfData = await getAuthData();
    return selfData.user.id;
}