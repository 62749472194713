import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ValueInput from "../forms/ValueInput";
import DescField from "../forms/DescField";
import FileUpload from "../forms/FileUpload";
import { Formik } from "formik";
import getImageBase64DataUri from "/src/utils/getImageBase64DataUri";
import {
  STICKER_ALLOWED_IMAGE_MAX_SIZE,
  STICKER_ALLOWED_IMAGE_MAX_SIZE_MESSAGE,
  STICKER_SUPPORTED_IMAGE_FORMATS,
} from "/src/constants/sticker";

const TemplateCreateForm = ({ initialValues, validationSchema, onSubmit }) => {
  const [imageFormatError, setImageFormatError] = useState(null);
  const [imageSizeError, setImageSizeError] = useState(null);

  const handleFileUpload = (e, setFieldValue) => {
    let file = e.target.files[0];
    console.log({ file });
    const { size, type: fileFormat } = file;

    if (!STICKER_SUPPORTED_IMAGE_FORMATS.includes(fileFormat)) {
      setImageFormatError(
        `Image could not be uploaded, not supported Image format. Format supported is (${STICKER_SUPPORTED_IMAGE_FORMATS.join(
          ","
        )})`
      );
    } else if (size > STICKER_ALLOWED_IMAGE_MAX_SIZE) {
      setImageSizeError(
        `Image file too large, max-size is ${STICKER_ALLOWED_IMAGE_MAX_SIZE_MESSAGE}`
      );
    } else {
      setImageFormatError(null);
      setImageSizeError(null);
      getImageBase64DataUri(file, (dataUri) => {
        setFieldValue("image", dataUri);
      });
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "md",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => (
          <Box
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              {values?.image && (
                <Box
                  sx={{
                    maxWidth: 200,
                    my: 3,
                    "& img": {
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={values?.image} alt='preview' />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".25rem",
                  }}
                >
                  <Typography
                    color={touched.image && errors.image ? "#ff0000" : ""}
                    variant='body2'
                    style={{
                      width: "70%",
                    }}
                  >
                    Required *
                  </Typography>

                  <FileUpload
                    required
                    name='image'
                    accept={STICKER_SUPPORTED_IMAGE_FORMATS.join(",")}
                    onImageChange={(e) => {
                      handleFileUpload(e, setFieldValue);
                    }}
                  />
                  <Box>
                    <Typography color='#313131' fontSize='16px'>
                      Upload Sticker
                    </Typography>
                    {imageFormatError && (
                      <Typography
                        color='#ff0000'
                        variant='body2'
                        style={{
                          width: "70%",
                        }}
                      >
                        {imageFormatError}
                      </Typography>
                    )}
                    {imageSizeError && (
                      <Typography
                        color='#ff0000'
                        variant='body2'
                        style={{
                          width: "70%",
                        }}
                      >
                        {imageSizeError}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <form autoComplete='off' onSubmit={handleSubmit}>
              <Box>
                <ValueInput
                  variant='outlined'
                  label='Title'
                  placeholder='Enter Title'
                  name='title'
                  value={values?.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && errors.title}
                  helperText={touched.title && errors.title}
                />
              </Box>
              <Box>
                <DescField
                  variant='outlined'
                  placeholder='Enter Description'
                  label='Description'
                  name='description'
                  value={values?.description}
                  multiline
                  rows={4}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box>
                <ValueInput
                  variant='outlined'
                  placeholder='Enter Sort Postion'
                  label='Sort Position'
                  type='number'
                  name='sortPosition'
                  value={values?.sortPosition}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.sortPosition && errors.sortPosition}
                  helperText={touched.sortPosition && errors.sortPosition}
                />
              </Box>
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Create Sticker
              </LoadingButton>
            </form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default TemplateCreateForm;
