import React from 'react';

const useContextFactory = (ctx) => {
    var context = React.useContext(ctx);
    if (context === undefined) {
        throw new Error(`${ctx} must be used with a provider.`);
    }
    return context;
}

export default useContextFactory