import PropTypes from 'prop-types';

import { Paper, Typography } from '@mui/material';

const NoDataFound = ({ error }) => {
  return (
    <Paper elevation={0} sx={{
        marginTop: 1,
        width: "100%",
        marginBottom: 0,
    }}>
      <Typography align="center" variant="subtitle1">
        No data
      </Typography>
      <Typography variant="body2" align="center">
        {
          (error)
            ? 'There was some error while fetching data. Please try again.'
            : 'Nothing to show here. Try again later.'
        }
      </Typography>
    </Paper>
  );
}

NoDataFound.propTypes = {
  error: PropTypes.any
}

export default NoDataFound;
