import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { Link } from "react-router-dom";
import NewsForm from "/src/components/forms/NewsForm";
import NewsTable from "/src/components/news/NewsTable";

import Page from "../components/Page";

const News = ({ loadFormComponent }) => {
  return (
    <Page title='News | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            News
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {loadFormComponent ? "dashboard/news/new" : "dashboard/news"}
          </Typography>
          {loadFormComponent ? (
            <Link to='/dashboard/news'>
              <Button
                variant='contained'
                color='primary'
                sx={{ marginBottom: 1 }}
              >
                GO BACK
              </Button>
            </Link>
          ) : (
            <Link to='/dashboard/news/new'>
              <Button
                variant='contained'
                color='primary'
                sx={{ marginBottom: 1 }}
              >
                ADD NEWS
              </Button>
            </Link>
          )}
        </Box>
        <Divider />
        <Box sx={{ pt: 3 }}>
          {loadFormComponent ? <NewsForm /> : <NewsTable />}
        </Box>
      </Container>
    </Page>
  );
};

export default News;
