import { PropTypes } from "prop-types";
import moment from "moment";

import { TableCell, TableRow, Button, Box } from "@mui/material";
import { Edit, DeleteForever } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";

const tableConstants = () => {
  return [
    {
      title: 'ID',
      key: 'maintenanceId',
    },
    {
      title: 'Start Time',
      key: 'startTime',
    },
    {
      title: 'End Time',
      key: 'endTime',
    },
    {
      title: 'Message',
      key: 'message',
    },
    {
        title: 'Action',
        key: 'action',
    }
  ];
};

const MaintenanceTable = (props) => {

    const { data } = {...props};
    return (
        <YMTableDesktop cols={tableConstants()}>
            {
              (!data || data.length === 0)
              ? <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              : data.map((maintenance, index) => (
                <TableRow key={index}>
                  <TableCell>
                      {maintenance.id}
                  </TableCell>
                  <TableCell>
                      { moment(maintenance.startTime).format('DD/MM/YYYY') }
                  </TableCell>
                  <TableCell>
                      { moment(maintenance.createdAt).format('DD/MM/YYYY') }
                  </TableCell>
                  <TableCell>
                      { maintenance.comment}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" width="100px" justifyContent="space-between">
                      <Button variant="outlined" startIcon={Edit} color="warning"></Button>
                      <Button variant="contained" startIcon={DeleteForever} color="warning"></Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            }
        </YMTableDesktop>
    )
}

MaintenanceTable.propTypes = {
    data: PropTypes.array,
}

export default MaintenanceTable;