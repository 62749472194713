import { useState } from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';

import Page from '../components/Page';
import SponsoredPostTable from '../components/posts/SponsoredPostTable';
import Filter from '../components/common/Filter';

const Sponsor = () => {

    const [search, setSearch] = useState();

    return (
        <Page title="Sponsor | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Post Sponsor
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        dashboard/sponsor
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    <SponsoredPostTable />
                </Box>
            </Container>
        </Page>
    );
}

export default Sponsor;