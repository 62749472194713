import { useEffect, useState } from 'react';
import { Box, Container, Typography, Divider, Chip, TextField } from '@mui/material';

import Page from '../components/Page';
import UserTable from '/src/components/user/UserTable';
import useUserSearch from '/src/utils/useUserSearch';

const AllUserList = () => {

    const [searchInput, setSearchInput] = useState('');
    const [totalUsers, updateTotalUserCount] = useState(0);
    const [filter, setFilter] = useState([]);

    const searchResult = useUserSearch(searchInput);

    useEffect(() => {
        if (searchResult && searchResult.data && searchResult.data.data) setFilter(searchResult.data.data)
        else setFilter([]) 
    }, [searchResult]);

    useEffect(() => {
        if (!searchInput) setFilter([]);
    }, [searchInput])

    const handleUserCountUpdate = (count) => {
        updateTotalUserCount(count);
    }

    return (
        <Page title="Users | YM Admin">
         <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Users <Chip color="primary" label={`Total Users - ${totalUsers}`} />
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        dashboard/users
                    </Typography>
                    <TextField
                        variant='standard'
                        label='Search User'
                        placeholder='Search by email or @[username] or #[display name] or :[EVM address]'
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        sx={{
                            marginBottom: '10px',
                            width: '40%'
                        }}
                    />
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    <UserTable updateTotalUserCount={(count) => handleUserCountUpdate(count)} filter={filter} searchError={searchResult?.error} />
                </Box>
            </Container>
        </Page>
    );
}

export default AllUserList;
