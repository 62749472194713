import PropTypes from 'prop-types';
import { Avatar, Box, Typography, Stack, Chip } from '@mui/material';
import getInitials from '../../utils/getInitials';

const UserInfoCellComponent = ({ username, displayName, imageURL, isBanned = false }) => {
    return (
        <Box sx={{
            alignItems: 'center',
            display: 'flex'
        }}>
            <Avatar
                src={imageURL}
                sx={{ mr: 2 }}
            >
                {getInitials(username)}
            </Avatar>
            <Stack>
                <Typography
                    color="text.primary"
                    variant="body1"
                >
                    { displayName }{' '}
                    {
                        (isBanned)
                        ? <Chip color='error' label='Banned' size='small' />
                        : ''
                    }
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body3"
                >
                    { username }
                </Typography>
            </Stack>
        </Box>
    )
}

UserInfoCellComponent.propTypes = {
    username: PropTypes.string,
    displayName: PropTypes.string,
    imageURL: PropTypes.string,
}

export default UserInfoCellComponent;