import { localAxios } from "/src/api/axios";

/**
 * 
 * @param {string} endPoint the endpoint to fetch data from
 * @param {number} pageSize per request; defaults to 10
 * @param {string} queryArgs any additional query string arguments; defaults to an empty string
 * @returns {Promise<any[]>} an array of data
 */
export async function getAllPages(endPoint, pageSize = 10, queryArgs = ''){
    const returnData = [];
    let lastDataSize = 0;
    let offset = 0;
    do {
      // add offset and limit to the endpoint; and then add extra query args if they are defined
      const fetchURL = `${endPoint}?offset=${offset}&limit=${pageSize}${queryArgs ? '&' : ''}${queryArgs}`;
      const res = await localAxios.get(fetchURL);
      if (res.status > 299 || res.status < 200) {
        const msg = `Response from API ${fetchURL} returned status ${res.status}`;
        console.error(msg);
        console.debug(res);
        throw new Error(msg);
      } else if (!res.data) {
        const msg = `Response from API ${fetchURL} contained no data`;
        console.error(msg);
        console.debug(res);
        throw new Error(msg);
      } else if (!Array.isArray(res.data.data)) {
        const msg = `Response from API ${fetchURL} did not have a data field of type array`;
        console.error(msg);
        console.debug(res);
        throw new Error(msg);
      } else {
        const newEntries = res.data.data;
        returnData.push(...newEntries);
        lastDataSize = newEntries.length;
        offset+= pageSize;
      }
    } while (lastDataSize)
    return returnData
}
