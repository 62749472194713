import { useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from '../components/Loading';
import { useAuthState, useAuthDispatch } from '/src/contexts/UserAuthContext';
import sidebarConfig from "/src/layouts/dashboard/SidebarConfig";

const VerifyLogin = () => {

    const { verifyLogin } = useAuthDispatch();
    const { loginError, loginMessage, userLoggedIn, user } = useAuthState();
    const location = useLocation();
    const navigate = useNavigate();
    const token = location.hash.substring(1);

    useEffect(() => {
        if (token) verifyLogin(token);
    }, [token]);

    useEffect(() => {
        if (userLoggedIn && user && user.role) {
            for (let i = 0; i < sidebarConfig.length; i++) {
                if (sidebarConfig[i].roles.includes(user.role)) {
                    if (sidebarConfig[i].children && sidebarConfig[i].children.length > 0) {
                        for (let j = 0; j < sidebarConfig[i].children.length; j++) {
                            if (sidebarConfig[i].children[j].roles.includes(user.role)) {
                                navigate(sidebarConfig[i].children[j].path);
                                break;
                            }
                        }
                        break;
                    } else {
                        navigate(sidebarConfig[i].path);
                        break;
                    }
                }
            }
        }
    }, [userLoggedIn])

    return (
        <>
            <Box sx={{
                marginTop: '150px'
            }}>
                <Loading />
                <Typography sx={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}>
                    {
                        (loginError)
                        ? `Error: ${loginError}`
                        : (loginMessage)
                            ? `Success: ${loginMessage}`
                            : 'Validating token and creating session....'
                    }
                </Typography>
            </Box>
        </>
    )
}

export default VerifyLogin;
