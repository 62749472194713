import { createWalletClient, custom } from 'viem';
import { polygon, polygonMumbai } from 'viem/chains';

const isMainnet = process.env.PARCEL_LENS_ENV === 'mainnet';
const lensChain = isMainnet ? polygon : polygonMumbai;

export const walletClient = createWalletClient({
    chain: lensChain,
    transport: custom(window.ethereum),
})


export const switchChain = async() => await walletClient.switchChain(lensChain);

export const getAddresses = async() => await walletClient.requestAddresses();

export const getDefaultAddress = async() => {
    const addresses = await getAddresses();
    if (addresses.length === 0) {
        throw new Error('No addresses found in wallet');
    }
    return addresses[0];
}

export const signText = async(account, message) => await walletClient.signMessage({account, message});
