import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Loading from "/src/components/Loading";
import Page from "/src/components/Page";
import StickerEditForm from "/src/components/stickers/StickerEditForm";
import { useGlobalMutation } from "/src/utils/useGlobalMutations";
import { useAlertDispatch } from "/src/contexts/GlobalAlertContext";
import { editSticker, getSticker } from "/src/api/query/sticker";

const StickerEdit = () => {
  const { stickerId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Calls the action that will upload the image in edit mode
  // const uploadEventImageAction = useGlobalMutation(uploadEventImage);

  const fetchData = () => {
    return getSticker(stickerId);
  };

  const { isLoading, data, error } = useQuery(
    [`stickers?id=${stickerId}`],
    fetchData
  );

  // Calls the action that will perform the edit event action and update the event list
  const editTemplateAction = useGlobalMutation(editSticker);

  // Dispatch actions for each alert
  const { successAlert, errorAlert } = useAlertDispatch();

  const queryClient = useQueryClient();

  // prepare my formik data
  const { title, description, tags, isNsfw, sortPosition, image } =
    data?.data?.[0] ?? {};

  const initialValues = {
    title,
    description,
    tags,
    isNsfw,
    isPublic: true,
    sortPosition,
    previewImage: image?.urlOriginal,
  };

  // just ensuring that image is returned
  if (!image?.id) {
    initialValues.image = "";
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(30, "Too long!").required("Required"),
    description: Yup.string().required("Required"),
    sortPosition: Yup.number().required("Required"),
    previewImage: Yup.string().required("Required"),
  });

  const onSubmit = (props, { setSubmitting }) => {
    setSubmitting(true);
    delete props.previewImage;
    // const newData = {
    //   ...props,
    //   tags: props?.tags?.filter((item) => item),
    // };
    editTemplateAction.mutate(
      {
        id: stickerId,
        data: props,
      },
      {
        onSuccess: (data) => {
          console.log({ data });
          setSubmitting(false);
          successAlert(`Template Updated`);
          queryClient.invalidateQueries(`stickers?id=${stickerId}`);
          navigate("/dashboard/stickers");
        },
        onError: (error) => {
          errorAlert(error.message);
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <Page title='Simple Events | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            Edit Stickers
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {pathname}
          </Typography>

          <Link to='/dashboard/stickers'>
            <Button
              variant='contained'
              color='primary'
              sx={{
                marginBottom: 1,
              }}
            >
              GO BACK
            </Button>
          </Link>
        </Box>
        <Divider />
        <Box sx={{ pt: 3 }}>
          {isLoading && <Loading />}
          {initialValues?.title && (
            <StickerEditForm
              stickerId={stickerId}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default StickerEdit;
