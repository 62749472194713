import React from "react";
import useContextFactory from "./context.util";
import { MODAL_DEFAULTS } from "/src/_helpers/modals";

var ModalStateContext = React.createContext();
var ModalDispatchContext = React.createContext();

const modalInitState = {
  showModal: false,
  modalTypeDefaults: {},
  modalProps: {},
};

const DELETE_USER_ACTION_TYPE = "DELETE_USER";
const DELETE_POST_ACTION_TYPE = "DELETE_POST";
const DELETE_AGENT_ACTION_TYPE = "DELETE_AGENT";
const DELETE_EVENT_ACTION_TYPE = "DELETE_EVENT";
export const DELETE_TEMPLATE_ACTION_TYPE = "DELETE_TEMPLATE";
export const DELETE_STICKER_ACTION_TYPE = "DELETE_STICKER";

const modalReducer = (state, action) => {
  switch (action.type) {
    case DELETE_USER_ACTION_TYPE:
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_USER_MODAL,
        modalProps: action.value,
      };
    case "VERIFY_USER":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.VERIFY_USER_MODAL,
        modalProps: action.value,
      };
    case "UNVERIFY_USER":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.UNVERIFY_USER_MODAL,
        modalProps: action.value,
      };
    case DELETE_POST_ACTION_TYPE:
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_POST_MODAL,
        modalProps: action.value,
      };
    case "REWARD_POST":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.REWARD_POST_MODAL,
        modalProps: action.value,
      };
    case DELETE_AGENT_ACTION_TYPE:
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_AGENT_MODAL,
        modalProps: action.value,
      };
    case DELETE_EVENT_ACTION_TYPE:
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_EVENT_MODAL,
        modalProps: action.value,
      };
    case DELETE_TEMPLATE_ACTION_TYPE:
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_TEMPLATE_MODAL,
        modalProps: action.value,
      };
    case DELETE_STICKER_ACTION_TYPE:
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_STICKER_MODAL,
        modalProps: action.value,
      };
    case "BAN_USER":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.BAN_USER_MODAL,
        modalProps: action.value,
      };
    case "UPDATE_USER_BAN":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.UPDATE_BAN_USER_MODAL,
        modalProps: action.value,
      };
    case "UNBAN_USER":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.UNBAN_USER_MODAL,
        modalProps: action.value,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        showModal: false,
        modalTypeDefaults: {},
        modalProps: {},
      };
    case "DELETE_REWARDED_POST_MODAL":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_REWARDED_POST_MODAL,
        modalProps: action.value,
      };
    case "CLOSE_EVENT_MODAL":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.CLOSE_EVENT_MODAL,
        modalProps: action.value,
      };
    case "DELETE_NEWS":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_NEWS_MODAL,
        modalProps: action.value,
      };
    case "UPDATE_MODAL_PROPS":
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          data: action.value,
        },
      };
    case "DELETE_CATEGORY":
      return {
        ...state,
        showModal: true,
        modalTypeDefaults: MODAL_DEFAULTS.DELETE_CATEGORY_MODAL,
        modalProps: action.value,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const GlobalModalProvider = ({ children }) => {
  var [state, dispatch] = React.useReducer(modalReducer, modalInitState);

  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

const useModalState = () => useContextFactory(ModalStateContext);
const useModalDispatch = () => useContextFactory(ModalDispatchContext);

export { GlobalModalProvider, useModalDispatch, useModalState };
