import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import peopleOutline from "@iconify/icons-eva/people-outline";
import personDeleteFill from "@iconify/icons-eva/person-delete-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import personFill from "@iconify/icons-eva/person-fill";
import atFill from "@iconify/icons-eva/at-fill";
import calendarFill from "@iconify/icons-eva/calendar-fill";
import settingsFill from "@iconify/icons-eva/settings-2-fill";
import activityFill from "@iconify/icons-eva/activity-fill";
import flashFill from "@iconify/icons-eva/flash-fill";
import { Roles } from "/src/_helpers/role";

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/home",
    icon: getIcon(pieChart2Fill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ANALYST],
  },
  {
    title: "users",
    path: "/dashboard/users",
    icon: getIcon(peopleFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ANALYST],
    children: [
      {
        title: "all",
        path: "/dashboard/users/all",
        icon: getIcon(peopleOutline),
        roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
      },
      {
        title: "banned users",
        path: "/dashboard/users/userban",
        icon: getIcon(personDeleteFill),
        roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
      },
    ],
  },
  {
    title: "posts",
    path: "/dashboard/posts",
    icon: getIcon(fileTextFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR, Roles.ANALYST],
  },
  {
    title: "moderators",
    path: "/dashboard/agents",
    icon: getIcon(personFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    title: "category and tags",
    path: "/dashboard/categoryandtags",
    icon: getIcon(atFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
    children: [
      {
        title: "categories",
        path: "/dashboard/categoryandtags/category",
        roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
      },
    ],
  },
  {
    title: "events and competition",
    path: "/dashboard/events",
    icon: getIcon(calendarFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.EVENT_MANAGER],
    children: [
      {
        title: "simple events",
        path: "/dashboard/events/main",
        roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.EVENT_MANAGER],
      },
      {
        title: "meme competition",
        path: "/dashboard/events/memecompetition",
        roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.EVENT_MANAGER],
      },
    ],
  },
  {
    title: "sponsored posts",
    path: "/dashboard/sponsor",
    icon: getIcon(flashFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    title: "news",
    path: "/dashboard/news",
    icon: getIcon("material-symbols:breaking-news-alt-1"),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.NEWS_MODERATOR],
  },
  {
    title: "Manage Templates",
    path: "/dashboard/templates",
    icon: getIcon(fileTextFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    title: "Manage Stickers",
    path: "/dashboard/stickers",
    icon: getIcon(fileTextFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    title: "maintenance",
    path: "/dashboard/maintenance",
    icon: getIcon(settingsFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
    disabled: true,
  },
  {
    title: "activity",
    path: "/dashboard/activity",
    icon: getIcon(activityFill),
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN],
    disabled: true,
  },
];

export default sidebarConfig;
